<template>
  <a
    v-tooltip="{ popperClass: 'chat_sidebar', placement: 'left', content: `${user.first_name} ${user.last_name}` }"
    class="relative cursor-default"
  >
    <avatar :color="user.color" :abbr="user.abbr" :image="user.profile_image" />

    <div
      v-if="user.voip_status !== null"
      class="absolute left-6 top-1 h-fit rounded-full p-1 text-white"
      :style="{ background: voipColor }"
    >
      <div class="h-fit w-full">
        <phone-fill size="0.5rem" />
      </div>
    </div>
  </a>
</template>

<script lang="ts">
import { PhoneFill } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import Avatar from '@/components/Avatar.vue';

import type { User } from '@/types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'UserIndicator',

  components: { PhoneFill, Avatar },

  props: {
    user: {
      type: Object as PropType<User>,
      default: () => ({}),
    },
  },

  computed: {
    voipColor(): 'green' | 'orange' | 'red' {
      switch (this.user.voip_status) {
        case 'ONLINE':
          return 'green';
        case 'CALLING':
          return 'orange';
        case 'OFFLINE':
        default:
          return 'red';
      }
    },
  },
});
</script>
