import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "w-100 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_badge = _resolveComponent("t-badge")!
  const _component_t_input_text = _resolveComponent("t-input-text")!
  const _component_t_error_item = _resolveComponent("t-error-item")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_t_input_text, {
          id: $props.inputId,
          ref: "textInput",
          modelValue: $options.computedValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.computedValue) = $event)),
          modelModifiers: { trim: true },
          placeholder: $props.placeholder,
          type: $props.inputType,
          maxlength: $props.maxLength,
          autofocus: $props.autoFocusInput,
          "has-error": !$props.isValidInput,
          max: $props.maxCharacters,
          disabled: $props.disabled,
          label: $props.label,
          "sub-label": $props.subLabel,
          "data-test": "wa-templates-input",
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('handle-input-change', $event))),
          onKeyup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('keyup', $event))),
          onPaste: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('paste', $event))),
          onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('blur', $event)))
        }, _createSlots({ _: 2 /* DYNAMIC */ }, [
          ($props.showTemplateEnd)
            ? {
                name: "end",
                fn: _withCtx(() => [
                  _createVNode(_component_t_badge, { text: $props.characterCount }, null, 8 /* PROPS */, ["text"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["id", "modelValue", "placeholder", "type", "maxlength", "autofocus", "has-error", "max", "disabled", "label", "sub-label"])
      ])), [
        [_directive_tooltip, 
          $props.tooltipText && {
            placement: 'bottom',
            content: $props.tooltipText,
            popperClass: 'tooltip-vuetify tooltip-medium',
          }
        ]
      ])
    ]),
    (!$props.isValidInput)
      ? (_openBlock(), _createBlock(_component_t_error_item, {
          key: 0,
          class: "mt-1",
          "data-test": "wa-input-error",
          text: $props.errorMsg
        }, null, 8 /* PROPS */, ["text"]))
      : _createCommentVNode("v-if", true)
  ]))
}