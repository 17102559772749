<template>
  <div>
    <div class="flex items-center justify-between">
      <div
        v-tooltip="
          tooltipText && {
            placement: 'bottom',
            content: tooltipText,
            popperClass: 'tooltip-vuetify tooltip-medium',
          }
        "
        class="w-100 w-full"
      >
        <t-input-text
          :id="inputId"
          ref="textInput"
          v-model.trim="computedValue"
          :placeholder="placeholder"
          :type="inputType"
          :maxlength="maxLength"
          :autofocus="autoFocusInput"
          :has-error="!isValidInput"
          :max="maxCharacters"
          :disabled="disabled"
          :label="label"
          :sub-label="subLabel"
          data-test="wa-templates-input"
          @input="$emit('handle-input-change', $event)"
          @keyup="$emit('keyup', $event)"
          @paste="$emit('paste', $event)"
          @blur="$emit('blur', $event)"
        >
          <template v-if="showTemplateEnd" #end>
            <t-badge :text="characterCount" />
          </template>
        </t-input-text>
      </div>
    </div>

    <t-error-item v-if="!isValidInput" class="mt-1" data-test="wa-input-error" :text="errorMsg" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'WaTemplateInput',
  props: {
    isValidInput: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validateBtnText: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    characterCount: {
      type: String,
      default: '',
    },
    maxCharacters: {
      type: Number,
      default: 25,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    autoFocusInput: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    inputId: {
      type: String,
      default: '',
    },
    showTemplateEnd: {
      type: Boolean,
      default: false,
    },
    subLabel: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
  emits: ['handle-input-change', 'keyup', 'paste', 'blur', 'update:modelValue'],
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
