import { PERIOD_TYPE } from '@/Configs/Constants';

import type { UsageResponse, BillingCycle } from '../modules/usage';

export const mockedMonthlyPeriod: BillingCycle = {
  chargebee_customer_id: '1',
  chargebee_subscription_id: 'BTUNcKUHG3R7wDlQ',
  billing_period_unit: PERIOD_TYPE.YEAR,
  period_end: 1735685999000,
  period_start: 1733007600000,
  balance_breakdown: {
    commitment: 250,
    consumption: 0,
    balance: 250,
  },
  conversation_breakdown: {
    commitment: 15,
    consumption: 0,
    balance: 15,
    in_progress: 0,
  },
};

export const mockedYearlyPeriod: BillingCycle = {
  billing_period_unit: PERIOD_TYPE.YEAR,
  chargebee_customer_id: '1',
  chargebee_subscription_id: 'BTUNcKUHG3R7wDlQ',
  period_end: 1751320799000,
  period_start: 1719784800000,
  balance_breakdown: {
    commitment: 250,
    consumption: 0,
    balance: 250,
  },
  conversation_breakdown: {
    commitment: 15,
    consumption: 0,
    balance: 15,
    in_progress: 0,
  },
};

export const mockedUsageOverview: UsageResponse = {
  conversations_closing_in_next_cycle: 0,
  current_period: mockedMonthlyPeriod,
  billing_cycles: [mockedYearlyPeriod],
};
