<template>
  <div class="users-list">
    <div class="wrapper">
      <users-list-header
        :is-mobile="isMobile"
        @open-modal="handleOpenAddModal"
        @open-roles-modal="handleOpenRolesModal"
      />
      <users-list-main
        :list="list"
        :loading="loading"
        :infinite-loading-reset-counter="infiniteLoadingResetCounter"
        :is-mobile="isMobile"
        @infinite-scroll="onInfinite"
        @open-modal="handleOpenEditUserModal"
        @resend-invite="resendInvite"
        @click-list-item="handleOpenEditUserModal"
        @delete="handleDeleteUser"
      />
    </div>
    <manage-roles-modal
      v-if="isManageRolesOpen && userStore.hasPermission(PERMISSION.PERMISSIONS__ROLES__MANAGE)"
      :is-open="isManageRolesOpen"
      @handle-open-roles-modal="handleOpenRolesModal"
    />
    <add-user-modal
      v-if="isAddUserOpen && userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)"
      v-model="isAddUserOpen"
      @update="$emit('update')"
    />
    <edit-user-modal
      v-if="isEditUserOpen && record && userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)"
      :is-open="isEditUserOpen"
      :initial-user="record"
      @update="$emit('update')"
      @delete="handleDeleteUser"
      @resend-invite="resendInvite"
      @close="isEditUserOpen = false"
    />
    <delete-user-modal
      v-if="isDeleteUserOpen && canDelete(user)"
      v-model="isDeleteUserOpen"
      :name="user?.first_name"
      @confirm="confirmDeleteUser"
    />
    <delete-team-chat-last-owner-modal
      v-if="isDeleteTeamChatLastOwnerOpen"
      v-model="isDeleteTeamChatLastOwnerOpen"
      class="delete-user-modal"
      :name="`${user?.first_name} ${user?.last_name}`"
      @confirm="handleDeleteUserAndPassOwnership"
      @cancel="handleCloseEditUserModal"
    >
      <template #body>
        <div class="flex flex-col pb-4 pt-4" :class="{ '-mr-2': isDesktop }">
          <div
            class="scroll-container"
            :class="{
              'scroll-on-hover': isDesktop && threads && threads.length > 6,
              'md:overflow-y-scroll': !isDesktop,
            }"
          >
            <div
              v-for="thread in computedThreads"
              :key="thread.id"
              class="w-100 flex items-center justify-between py-4 pl-4 pr-4 md:py-3 md:pl-8 md:pr-8 lg:pl-0"
            >
              <div class="flex items-center">
                <hashtag-linear class="icon-hashtag-linear mr-3 text-grey-400" />
                <div v-if="thread" class="title text-ellipsis font-medium text-grey-900">
                  {{ thread.subject }}
                </div>
              </div>
              <div class="subtitle text-ellipsis pl-3 font-medium text-grey-700">
                {{ getThreadOwnerAsUser(thread).fullName }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </delete-team-chat-last-owner-modal>
  </div>
</template>

<script lang="ts">
import { HashtagLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import {
  deleteThread,
  deleteUser,
  getPaginatedThreads,
  sendVerification,
  updateThreadParticipant,
} from '@/components/Users/api';
import AddUserModal from '@/components/Users/components/AddUsers/AddUserModal';
import DeleteTeamChatLastOwnerModal from '@/components/Users/components/DeleteTeamChatLastOwnerModal';
import DeleteUserModal from '@/components/Users/components/DeleteUserModal';
import EditUserModal from '@/components/Users/components/EditUsers/EditUserModal';
import ManageRolesModal from '@/components/Users/components/ManageRoles/ManageRolesModal';
import { AUTH_URL, PERMISSION, FEATURE } from '@/Configs/Constants';
import { useEntitlementsStore, useUserStore, useUsersStore } from '@/store/pinia';
import breakpoints from '@/util/breakpoints';
import { duplicateObject } from '@/util/helpers';

import UsersListHeader from '../../components/UsersListHeader';
import UsersListMain from '../../components/UsersListMain';
import { useRolesDropdown } from '../../composables/rolesDropdown';
export default defineComponent({
  name: 'UsersList',
  components: {
    AddUserModal,
    ManageRolesModal,
    DeleteTeamChatLastOwnerModal,
    DeleteUserModal,
    EditUserModal,
    UsersListMain,
    UsersListHeader,
    HashtagLinear,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    infiniteLoadingResetCounter: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update', 'infinite-scroll'],
  data() {
    return {
      isAddUserOpen: false,
      isManageRolesOpen: false,
      isEditUserOpen: false,
      isDeleteUserOpen: false,
      isDeleteTeamChatLastOwnerOpen: false,
      record: null,
      threads: null,
      breakpoints,
      user: null,
      PERMISSION,
    };
  },
  computed: {
    ...mapStores(useEntitlementsStore, useUserStore, useUsersStore),
    ...mapGetters({
      getUserById: 'usersInternalChat/userById',
    }),
    computedThreads() {
      return this.threads?.filter((thread) => thread.participants.length !== 1);
    },
    isMobile() {
      return !breakpoints.comparisons.gt_md;
    },
    isDesktop() {
      return breakpoints.comparisons.gt_md;
    },
  },
  methods: {
    handleCloseEditUserModal() {
      this.isEditUserOpen = false;
    },
    onInfinite(scroll) {
      this.$emit('infinite-scroll', scroll);
    },
    handleOpenAddModal() {
      this.isAddUserOpen = true;
    },
    handleOpenRolesModal() {
      this.isManageRolesOpen = !this.isManageRolesOpen;
    },
    closeOpenRolesModal() {
      this.isManageRolesOpen = false;
    },
    handleOpenEditUserModal(user) {
      this.record = duplicateObject(user);
      this.isEditUserOpen = true;
    },
    resendInvite(userId) {
      sendVerification(userId)
        .then(() => {
          this.flashSuccess('Invite was sent successfully');
        })
        .catch((e) => {
          console.error('Error while sending invite: ', e);
        });
    },
    canDelete(user) {
      return useRolesDropdown(user).userCanBeDeleted;
    },
    // delete user logic
    async handleDeleteUser(user) {
      this.user = user;
      if (!this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE_TEAMCHAT)) {
        this.isDeleteUserOpen = true;
        return;
      }
      // check and pass owner where the deleted user is the only owner of a team chat group thread
      this.threads = this.getThreadsWithNewOwner(await this.mergePaginatedThreads());

      const threadSingleOwnerWithMultipleParticipants = this.threads.filter((thread) => thread.participants.length > 1);
      if (this.threads?.length === 0 || threadSingleOwnerWithMultipleParticipants.length === 0) {
        this.isDeleteUserOpen = true;
      } else {
        this.isDeleteTeamChatLastOwnerOpen = true;
      }
    },
    async handleDeleteUserAndPassOwnership() {
      for (const thread of this.threads) {
        const filteredParticipants = thread.participants.filter((participant) => {
          return (
            (participant.id !== this.user.id &&
              this.getUserById(participant.id).fullName !== 'Deleted user #' + participant.id) ||
            participant.id === this.user.id
          );
        });
        if (filteredParticipants.length === 1) {
          await this.performDeleteThread(thread);
          continue;
        }

        await this.performUpdateThreadParticipant(thread);
      }

      this.performDeleteUser();
    },
    async confirmDeleteUser() {
      if (!this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE_TEAMCHAT)) {
        await this.performDeleteUser();
        return;
      }
      // delete threads where the deleted user is the only participant in a team chat thread
      if (this.threads?.length) {
        for (const thread of this.threads) {
          await this.performDeleteThread(thread);
        }
      }
      await this.performDeleteUser();
    },
    async performDeleteUser() {
      deleteUser(this.user.id)
        .then(() => {
          this.usersStore.reload().then(() => {
            this.$root.users = this.usersStore.users; // FIXME: remove this after fully migrated to pinia
          });

          if (!this.$root.users.length) {
            window.location.href = AUTH_URL.LOGOUT;
          }
          this.$emit('update');
          this.isEditUserOpen = false;
          this.handleCloseEditUserModal();
          this.flashSuccess(this.user.first_name + ' was removed successfully.');
        })
        .catch((e) => {
          console.error('Error on deleting user,', e);
        });
    },
    async performGetPaginatedThreads(page) {
      return getPaginatedThreads(page, { user_id: this.user.id, user_is_only_owner: true })
        .then((threads) => {
          return threads.data;
        })
        .catch((e) => {
          console.error('Error on retrieving threads,', e);
        });
    },
    performUpdateThreadParticipant(thread) {
      return updateThreadParticipant(thread.id, {
        userId: this.user.id,
        subject: thread.subject,
        participants: thread.participants,
      }).catch((e) => {
        console.error('Error on updating thread participants,', e);
      });
    },
    performDeleteThread(thread) {
      return deleteThread(thread.id).catch((e) => {
        console.error('Error on deleting thread,', e);
      });
    },
    getThreadsWithNewOwner(threads) {
      return threads.map((thread) => {
        this.replaceThreadOwner(thread, this.getRandomParticipant(thread));
        return thread;
      });
    },
    async mergePaginatedThreads() {
      let paginatedThreads = await this.performGetPaginatedThreads(1);
      let threads = paginatedThreads.data;

      for (let page = 2; page <= paginatedThreads.meta.last_page; page++) {
        paginatedThreads = await this.performGetPaginatedThreads(page);
        threads = threads.concat(paginatedThreads.data);
      }
      return threads;
    },
    getRandomParticipant(thread) {
      const filteredParticipants = thread.participants.filter((participant) => {
        return (
          participant.id !== this.user.id &&
          this.getUserById(participant.id).fullName !== 'Deleted user #' + participant.id
        );
      });

      const diceAsKey = Math.floor(Math.random() * filteredParticipants.length);

      return filteredParticipants[diceAsKey];
    },
    replaceThreadOwner(thread, user) {
      thread.participants.forEach((participant, index) => {
        if (user && (participant.id === user.id || participant.id === this.user.id)) {
          thread.participants[index] = {
            ...participant,
            owner: participant.id === user.id ? 1 : 0,
          };
        }
      });
      return thread;
    },

    getThreadOwnerAsUser(thread) {
      return thread.participants
        .filter((participant) => participant.owner === 1)
        .map((participant) => this.getUserById(participant.id))[0];
    },
  },
});
</script>

<style scoped lang="scss" src="./UsersList.scss" />
