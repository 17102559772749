export const FEATURE_FLAG_INBOX = {
  BETA_PROGRAM: 'TI_BETA_Program',
  COMMENT_FEED_REDESIGN: 'TI_Comment_Feed_Redesign',
  CONSOLIDATED_MESSAGE_ENDPOINT_ENABLED: 'TI_Consolidated_Message_Endpoint_Enabled',
  CREATED_AT_FILTERING: 'TI_Created_At_Date_Filtering',
  INTEGRATIONS_ENTITLEMENT_BYPASS: 'TI_integration_hub_integrations_entitlement_bypass',
  INTEGRATION_HUB: 'TI_Integration_Hub',
  INTERNAL_COMMENT_ATTACHMENTS: 'TI_InternalCommenting_Attachments',
  INTERNAL_COMMENT_TABS: 'TI_InternalCommenting_Tabs',
  MESSAGE_DELIVERY_STATUS: 'TI_message_delivery_status',
  PROFILE_MERGING: 'TI_Merging_Profiles',
  SETTINGS_SUMMARY_LIST: 'TI_Inbox_Settings_Summary_List',
  TI_CG_FRONTEND: 'TI_Contact_Groups_FE',
  TI_CONVERSATION_SUMMARY: 'TI_Conversation_Summary',
  TI_SUGGESTED_REPLY: 'TI_Suggested_Reply',
  TRANSLATION_ALWAYS_AVAILABLE: 'TI_Translation_Always_Available',
} as const;

export const FEATURE_FLAG_SETTINGS = {
  TA_BYPASS_DANGER_ZONE_VERIFICATION: 'TA_Bypass_Danger_Zone_Verification',
  TA_SETTINGS_PAGE: 'TA_Settings_Page',
  TA_SETTINGS_SIDEBAR: 'TA_Settings_Sidebar',
  TA_SETTINGS_FLOWBOT: 'TA_Settings_Flow_Bot_Page',
  TA_SETTINGS_AUTOREPLY: 'TA_Settings_Auto_Replies_Page',
  TA_CHATBOTS_PAGE: 'TA_Settings_Chat_Bots_Page',
  SETTINGS_INBOX_BETA: 'Ti_Settings_Inbox_Beta',
} as const;

export const FEATURE_FLAG_CHANNELS = {
  POWERED_BY_TRENGO: 'TC_Powered_By_Trengo',
  TC_KEYREPLY_FIRST_CUT_OFF_WARNING: 'TC_Keyreply_First_Cut_Off_Warning',
  TC_KEYREPLY_SECOND_CUT_OFF_WARNING: 'TC_KeyReply_Second_Cut_Off_Warning',
  TC_OTHER_WA_PROVIDERS_FIRST_CUT_OFF_WARNING: 'TC_Other_Wa_Providers_First_Cut_Off_Warning',
  TC_OTHER_WA_PROVIDERS_SECOND_CUT_OFF_WARNING: 'TC_Other_Wa_Providers_Second_Cut_Off_Warning',
  TC_KEYREPLY_DISCONNECTION: 'TC_KeyReply_Disconnection',
  TC_OTHER_WA_PROVIDERS_DISCONNECTION: 'TC_Other_Wa_providers_Disconnection',
  TC_EMAIL_OUTBOUND_SMTP: 'TC_Email_Outbound_SMTP',
  TC_NYLAS_EMAIL_CHANNEL: 'TC_Nylas_Email_Channel',
  TC_NYLAS_EMAIL_CHANNEL_V3: 'TC_Nylas_Email_Channel_V3',
  TC_HIDE_GBM: 'TC_Hide_Gbm',
} as const;

export const FEATURE_FLAG_ACTIVATION = {
  TA_CACHE_REQUEST: 'TA_Cache_Requests',
  TA_ONBOARDING_CHANNEL_SELECTION: 'TA_Onboarding_Channel_selection',
  TA_TIERED_SUPPORT_WIDGET: 'TA_Tiered_Support_Widget',
  TA_FRONTEND_TRACKING: 'TA_FrontEnd_Tracking',
  AI_SETTINGS: 'TA_AI_Frontend',
  AI_LANDING_PAGE: 'TA_AI_Landing_Frontend',
} as const;

export const FEATURE_FLAG_VOLUME = {
  TVE_NYLAS_DEPRECATE_CHANNEL_PROVIDERS: 'TVE_Nylas_Deprecate_Channel_Providers',
  TVE_NEW_TO_CC_BCC_UX: 'TVE_New_To_CC_BCC_UX',
  TVE_SHOW_BANNER_FOR_AUTO_TOPUP: 'TVE_Show_Banner_for_auto_topup',
} as const;

export const FEATURE_FLAG_SUBSCRIPTION = {
  TW_ENABLE_ENTITLEMENTS: 'TW_Enable_Entitlements',
  TW_FREEZE_REGISTRATION: 'TW_Freeze_Registration',
  TW_QUARTERLY_BILLING_CUSTOMERS: 'TW_Quarterly_Billing_Customers',
  TW_USAGE_BASED_MIGRATION_BANNER: 'TW_Usage_Based_Migration_Banner',
} as const;

export type FeatureFlag =
  | valueof<typeof FEATURE_FLAG_INBOX>
  | valueof<typeof FEATURE_FLAG_SETTINGS>
  | valueof<typeof FEATURE_FLAG_CHANNELS>
  | valueof<typeof FEATURE_FLAG_ACTIVATION>
  | valueof<typeof FEATURE_FLAG_SUBSCRIPTION>;
