import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { type BillingCycle, type UsageResponse, fetchUsageOverview } from '@/api';
import { PERIOD_TYPE } from '@/Configs/Constants';

import type { PeriodBreakdown } from '../types';

const EURO_IN_CENTS = 100;

export const useUsageOverviewStore = defineStore('usage', () => {
  const isPending = ref(false);
  const hasError = ref(false);

  const breakdowns = ref<PeriodBreakdown[]>([]);
  const currentCycleIndex = ref(-1);
  const selectedBreakdownIndex = ref(-1);

  const getUsageOverview = async () => {
    isPending.value = true;
    breakdowns.value = [];
    try {
      const { data } = await fetchUsageOverview();
      prepareBreakdownList(data);
    } catch (err) {
      hasError.value = true;
      console.error(err);
    } finally {
      isPending.value = false;
    }
  };

  const toCents = (amount: number) => amount * EURO_IN_CENTS;

  const mapBreakdown = (breakdown: BillingCycle): PeriodBreakdown => ({
    periodType: breakdown.billing_period_unit ?? PERIOD_TYPE.UNKNOWN,
    startDate: breakdown.period_start,
    endDate: breakdown.period_end,
    conversationBreakdown: {
      ...breakdown.conversation_breakdown,
      inProgress: breakdown.conversation_breakdown.in_progress,
    },
    carrierCostBreakdown: {
      consumption: toCents(breakdown.balance_breakdown.consumption),
      commitment: toCents(breakdown.balance_breakdown.commitment),
    },
  });

  const prepareBreakdownList = (usageResponse: UsageResponse | null) => {
    if (!usageResponse) return;

    const { billing_cycles, current_period, conversations_closing_in_next_cycle } = usageResponse;

    (billing_cycles ?? [])
      .sort((a, b) => a.period_start - b.period_start)
      .forEach((breakdown) => {
        breakdowns.value.push(mapBreakdown(breakdown));
      });
    if (current_period) {
      breakdowns.value.push(mapBreakdown(current_period));
    }

    if (conversations_closing_in_next_cycle) {
      breakdowns.value.push({
        periodType: PERIOD_TYPE.UPCOMING,
        startDate: 0,
        endDate: 0,
        conversationBreakdown: {
          inProgress: conversations_closing_in_next_cycle,
          consumption: 0,
        },
      });
    }

    const currentIndex = breakdowns.value.findIndex((item) => item.startDate === current_period?.period_start);
    currentCycleIndex.value = currentIndex;
    setSelectedBreakdownIndex(currentIndex);
  };

  const setSelectedBreakdownIndex = (index: number) => {
    selectedBreakdownIndex.value = index;
  };

  const hasUsage = computed(
    () => !isPending.value && !hasError.value && breakdowns.value.length > 0 && selectedBreakdown.value,
  );

  const selectedBreakdown = computed(() => breakdowns.value[selectedBreakdownIndex.value]);
  const isCurrentCycle = computed(() => selectedBreakdownIndex.value === currentCycleIndex.value);
  const isUpcoming = computed(() => selectedBreakdown.value.periodType === PERIOD_TYPE.UPCOMING);
  const isTrial = computed(() => selectedBreakdown.value.periodType === PERIOD_TYPE.TRIAL);
  const isYearly = computed(() => selectedBreakdown.value.periodType === PERIOD_TYPE.YEAR);
  const isUnknown = computed(() => selectedBreakdown.value.periodType === PERIOD_TYPE.UNKNOWN);

  return {
    isPending,
    hasUsage,
    breakdowns,
    currentCycleIndex,
    selectedBreakdownIndex,
    selectedBreakdown,
    isCurrentCycle,
    isUpcoming,
    isTrial,
    isYearly,
    isUnknown,
    hasError,
    setSelectedBreakdownIndex,
    getUsageOverview,
  };
});
