<template>
  <t-modal
    :model-value="isOpen"
    :is-closable="!isSubmitting"
    :title="t('usage_overview.add_conversation_blocks_modal_title')"
    data-test="addConversationBlocksModal"
    variant="default"
    @close="handleClose"
  >
    <p class="t-text-md m-0 text-grey-700" v-html="t('usage_overview.add_conversation_blocks_modal_description')" />
    <template v-if="isOpen">
      <div class="flex items-center gap-3 border-b-1 border-grey-300 py-4 last:border-0">
        <t-input-stepper v-model="conversationBlocksAmount" size="md" :max="400" :disabled="isSubmitting" />
        <div>
          <label class="t-text-sm-emphasize m-0">
            {{ t(`usage_overview.add_conversation_blocks_modal_conversation_blocks_label_standard`) }}
          </label>
          <span class="t-text-sm block text-grey-600">
            {{
              t('usage_overview.add_conversation_blocks_modal_price_breakdown_label', {
                price: formatPrice(CONVERSATION_BLOCK_PRICE),
              })
            }}
          </span>
        </div>
        <span class="t-text-sm-emphasize ml-auto">
          {{ formatPrice(CONVERSATION_BLOCK_PRICE * conversationBlocksAmount) }}
        </span>
      </div>
    </template>

    <template #footer>
      <div class="flex w-full justify-end gap-2">
        <t-button btn-style="secondary" @click="handleClose">
          {{ t('general.cancel') }}
        </t-button>
        <t-button
          class="flex items-center"
          btn-style="primary"
          :disabled="!isDirty || isSubmitting"
          @click="handleSubmit"
        >
          {{ t('usage_overview.add_conversation_blocks_modal_cta') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { postOneTimePurchaseTopUpCommitment } from '@/api';
import { useQueryParameterOpenState } from '@/composables/useQueryParameterOpenState';
import { SUBSCRIPTION_FREQUENCY } from '@/Configs/Constants';
import { useSubscriptionStore } from '@/store/pinia';
import { segmentTrackEvent } from '@/util/analytics/segmentAnalytics';
import { formatPrice } from '@/util/currency';
import { flashError } from '@/util/flashNotification';

const subscriptionStore = useSubscriptionStore();
const { t } = useI18n();

const isSubmitting = ref(false);

const CONVERSATION_BLOCK_PRICE = computed(() =>
  subscriptionStore.subscription?.subscriptionFrequency === SUBSCRIPTION_FREQUENCY.ANNUALLY ? 1500 : 1800,
);

const { isOpen, handleClose } = useQueryParameterOpenState('addConversationBlocksModal');
const emit = defineEmits(['success']);

const conversationBlocksAmount = ref(0);

const isDirty = computed(() => conversationBlocksAmount.value);

const resetForm = () => {
  conversationBlocksAmount.value = 0;
};

const handleSegmentTracking = () => {
  segmentTrackEvent({
    event_name: 'extra_usage',
    action: 'added',
    category: 'conversations',
    object_title: 'Add Conversations',
    subscription_id: subscriptionStore.subscription.subscriptionId,
    amount: conversationBlocksAmount.value,
  });
};

const handleSubmit = async () => {
  isSubmitting.value = true;
  try {
    await postOneTimePurchaseTopUpCommitment({
      standard_conversation_blocks: conversationBlocksAmount.value,
    });
    handleSegmentTracking();
    handleClose();
    emit('success');
    resetForm();
  } catch (error) {
    flashError('Something went wrong.');
    console.error(error);
  } finally {
    isSubmitting.value = false;
  }
};
</script>
