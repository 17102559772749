import type { ServiceNotification } from './types';

export const POLLING_INTERVAL = 30000; // 5 minutes
export const IS_DISMISSED_KEY = 'pricing_migration_service_notification_dismissed';

export const MIGRATION_CUSTOM_NOTIFICATION: ServiceNotification = {
  id: 'pricing_migration_custom_notification',
  status: 'info',
  notification_type: '',
  message:
    'You will soon be migrated to a new plan. An email containing further information will be sent to the admin.',
  link_url: 'https://www.youtube.com/watch?v=sbXvEjPsDqs',
  link_text: 'Watch video',
  is_dismissible: true,
};

export const USAGE_NOTIFICATION_TYPES: Partial<ServiceNotification['notification_type']>[] = [
  '100_percentage_usage',
  '90_percentage_usage',
  '75_percentage_usage',
  '100_percentage_carrier_cost_usage',
  '90_percentage_carrier_cost_usage',
  '75_percentage_carrier_cost_usage',
];

// agencies that we don't show the unpaid invoice banner to. See: WALL-515, WALL-632
export const WHITELISTED_AGENCY_IDS = [169753, 324388, 324589];
