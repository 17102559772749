import { defineStore } from 'pinia';
import { ref } from 'vue';

import { SETTINGS_URL } from '@/Configs/Constants';
import { SETTINGS_TRACKING_NAVIGATION_ITEMS } from '@/util/analytics/settingsTrackingNavigationItems';

import type { RouteLocationNormalized } from 'vue-router';

const ADMIN_PATHS: string[] = Object.values(SETTINGS_URL);

export const useRoutesStore = defineStore('routes', () => {
  const activeSettingsBaseRoutePath = ref('');
  const activeSettingsFeatureName = ref('');
  const activeSettingsNavigationGroupName = ref('');
  const currentRouteFullPath = ref('');
  const currentRoutePath = ref('');
  const isAdminRoute = ref(false);
  const lastRouteFullPath = ref('');
  const lastRoutePath = ref('');

  const isValidPath = (path: string): boolean => {
    return path !== '' && path.includes('/');
  };

  const getSettingsBaseRoute = (currentRoutePath: string, activeSettingsUrl: string) =>
    currentRoutePath?.startsWith(activeSettingsUrl) ? activeSettingsUrl : '';

  const setAdminFields = (path: string) => {
    const navigationItem = SETTINGS_TRACKING_NAVIGATION_ITEMS.flatMap((group) => group.items).find(
      (menuItem) => menuItem.url === getSettingsBaseRoute(path, menuItem.url),
    );
    activeSettingsBaseRoutePath.value = navigationItem?.url || '';
    activeSettingsFeatureName.value = navigationItem?.trackingId || '';

    const navigationGroup = SETTINGS_TRACKING_NAVIGATION_ITEMS.find((group) =>
      group.items.find((menuItem) => menuItem.url === activeSettingsBaseRoutePath.value),
    );
    activeSettingsNavigationGroupName.value = navigationGroup?.trackingGroupId || '';
  };

  const updateRouteState = (to: RouteLocationNormalized, from: RouteLocationNormalized): void => {
    if (!isValidPath(to.path)) {
      console.error('The given path is an Invalid path');
      return;
    }

    lastRoutePath.value = from.path;
    lastRouteFullPath.value = from.fullPath;
    currentRoutePath.value = to.path;
    currentRouteFullPath.value = to.fullPath;

    isAdminRoute.value = ADMIN_PATHS.includes(to.path);

    if (isAdminRoute.value) {
      setAdminFields(to.fullPath);
    } else {
      activeSettingsBaseRoutePath.value = '';
      activeSettingsFeatureName.value = '';
      activeSettingsNavigationGroupName.value = '';
    }
  };

  return {
    activeSettingsBaseRoutePath,
    activeSettingsFeatureName,
    activeSettingsNavigationGroupName,
    currentRouteFullPath,
    currentRoutePath,
    isAdminRoute,
    lastRouteFullPath,
    lastRoutePath,
    updateRouteState,
  };
});
