<template>
  <t-modal
    :model-value="isOpen"
    class="edit-user-modal"
    variant="default"
    :title="t('user_management.edit_user_details')"
    @close="closeModal"
  >
    <div class="mb-4" data-test="edit-user-form">
      <div>
        <div class="t-text-md-emphasize mb-2 text-grey-800">
          {{ t('user_management.email') }}
        </div>
        <t-input-text v-model="user.email" size="md" data-test="email-input" disabled />
      </div>
      <div>
        <div class="t-text-md-emphasize mb-2 mt-6 text-grey-800">{{ t('user_management.name') }}</div>
        <div class="md:flex">
          <div class="md:mr-1 md:w-1/2">
            <t-input-text
              v-model="user.first_name"
              :placeholder="t('user_management.first_name')"
              size="md"
              required
              autofocus
              :has-error="!validation.firstname"
              data-test="firstname-input"
              @keydown="handleKeyboardEvent($event)"
            />
            <t-error-item
              v-if="!validation.firstname"
              :text="t('user_management.validation_first_name_required')"
              data-test="firstname-error"
            />
          </div>
          <div class="mt-2 md:ml-1 md:mt-0 md:w-1/2">
            <t-input-text
              v-model="user.last_name"
              :placeholder="t('user_management.last_name')"
              size="md"
              :has-error="!validation.lastname"
              data-test="lastname-input"
              @keydown="handleKeyboardEvent($event)"
            />
            <t-error-item
              v-if="!validation.lastname"
              :text="t('user_management.validation_last_name_required')"
              data-test="lastname-error"
            />
          </div>
        </div>
      </div>
      <div class="t-text-md-emphasize mt-6 text-grey-800">{{ t('user_management.user_role') }}</div>
      <div
        v-if="!isEditRoleDropdownEnabled"
        class="t-text-sm mt-1 text-grey-600"
        data-test="subscription-user-limit-info"
      >
        {{ t('user_management.you_have_reached_limit_message') }}
      </div>
      <t-dropdown
        :key="user.role.id"
        v-model="user.role.name"
        :items="roles"
        :placeholder="t('user_management.pick_an_option')"
        size="md"
        value-index="value"
        text-index="title"
        :error="!validation.role"
        :disabled="!isEditRoleDropdownEnabled || isTheOnlyAdministrator"
        container-text-ellipsis
        class="mt-2"
        data-test="authorization-input"
      />
      <t-error-item
        v-if="!validation.role"
        :text="t('user_management.please_select_a_user_role')"
        data-test="authorization-error"
      />
      <t-inline-banner v-if="!isLoading && isTheOnlyAdministrator" class="mt-3" data-test="user-role-validation-info">
        <template #icon>
          <info-linear />
        </template>
        <template v-if="isTheOnlyAdministrator">
          <div>{{ t('user_management.organisation_must_have_at_least_one_administrator') }}</div>
        </template>
      </t-inline-banner>
      <t-inline-banner
        v-if="!isLoading && isObserver(initialUser.role.name) && !isObserver(user.role.name)"
        class="mt-3"
        data-test="user-role-upgrade-info"
      >
        <template #icon>
          <info-linear />
        </template>
        <div>{{ t('user_management.upgrading_this_user_to_another_role_can_not_be_reversed') }}</div>
      </t-inline-banner>
      <div class="t-text-md-emphasize mb-2 mt-6 text-grey-800">{{ t('user_management.teams') }}</div>
      <t-multi-select
        v-model="user.teams"
        :placeholder="t('user_management.select_one_or_more_teams')"
        :use-icon="false"
        :has-avatar="false"
        is-closable
        :data="teams"
        size="md"
        variant="leaf"
        :error="!validation.teams"
        limited-height
        data-test="teams-input"
      />
      <t-error-item
        v-if="!validation.teams"
        :text="t('user_management.please_select_a_team')"
        data-test="teams-error"
      />
      <div class="t-text-md-emphasize mb-2 mt-6 text-grey-800">{{ t('user_management.language') }}</div>
      <t-dropdown
        v-model="user.locale_code"
        size="md"
        :placeholder="t('user_management.select_a_language')"
        :items="languageItems"
        value-index="id"
        text-index="title"
        data-test="language-input"
      />
      <div class="t-text-md-emphasize mb-2 mt-6 inline-flex items-center text-grey-800">
        <span>{{ t('user_management.account_owner') }}</span>
        <span
          key="tooltipPlan"
          v-tooltip="{
            placement: 'bottom',
            content: t(
              'user_management.the_account_owner_receives_important_emails_about_invoices_account_and_subscription_updates',
            ),
            popperClass: 'tooltip-vuetify tooltip-medium',
            trigger: tooltipEvent,
          }"
          class="tooltip-user-modal ml-1.5 flex h-16px w-16px cursor-default items-center justify-center rounded-full bg-grey-600 font-bold text-white hover:bg-grey-800"
        >
          i
        </span>
      </div>
      <t-dropdown
        v-model="isPrimary"
        size="md"
        :items="isPrimaryItems"
        value-index="id"
        text-index="title"
        :disabled="isTheOnlyPrimaryUser"
        data-test="is-primary-input"
      />
      <t-inline-banner v-if="!isLoading && isTheOnlyPrimaryUser" class="mt-3" data-test="account-owner-validation-info">
        <template #icon>
          <info-linear />
        </template>
        <div>{{ t('user_management.you_need_to_make_another_user_the_primary_user_to') }}</div>
      </t-inline-banner>
      <t-button
        v-if="userCanBeDeleted && isMobile"
        btn-style="secondary"
        type="button"
        class="t-text-button-md mt-8 w-full"
        data-test="delete-user-button"
        @click="deleteUser"
      >
        <span class="text-error-500">{{ t('user_management.delete_user') }}</span>
      </t-button>
    </div>
    <template #footer>
      <t-button v-if="isUserUnverified" btn-style="secondary" data-test="resend-invite-button" @click="resendInvite()">
        {{ t('user_management.resend_invite') }}
      </t-button>
      <t-button btn-style="secondary" data-test="" @click="closeModal">
        {{ t('user_management.cancel') }}
      </t-button>
      <t-button btn-style="primary" data-test="resend-invite-button" :class="{ loader: isLoading }" @click="save">
        {{ t('user_management.save_changes') }}
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts" setup>
import { InfoLinear } from '@trengo/trengo-icons';
import { isEqual, cloneDeep } from 'lodash';
import { computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import { vm } from '@/ApplicationHandler';
import { USER_ROLE } from '@/Configs/Constants';
import UserStatus from '@/Configs/Constants/UserStatus';
import { useUsersStore, useUserStore } from '@/store/pinia';
import { identifySegmentUser } from '@/util/analytics/segmentAnalytics';
import { useMaxWidth } from '@/util/breakpointHelpers';
import { flashSuccess } from '@/util/flashNotification';

import { useRolesDropdown } from '../../../composables/rolesDropdown';
import { editUser, getAllTeams, sendVerification } from '../Api';

import type { RoleItem } from '@/components/Users/composables/types';
import type { Team, User } from '@/types';
import type { PropType } from 'vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  initialUser: {
    type: Object as PropType<User>,
    default: () => ({}),
  },
});

const emit = defineEmits(['update', 'delete', 'close']);
const { t } = useI18n();
const userStore = useUserStore();
const usersStore = useUsersStore();

const user = ref<User>(cloneDeep(props.initialUser));
const teams = ref<Team[]>([]);
const isLoading = ref<boolean>(false);
const initialIsPrimaryValue = props.initialUser?.is_primary === 1 ? 'yes' : 'no';
const isPrimary = ref<'yes' | 'no'>(initialIsPrimaryValue);

const isMobile = useMaxWidth('lg');

const languageItems = computed(() => [
  { id: 'nl-NL', title: t('user_management.nl') },
  { id: 'en-GB', title: t('user_management.en') },
]);
const isPrimaryItems = computed(() => [
  { id: 'yes', title: t('user_management.yes') },
  { id: 'no', title: t('user_management.no') },
]);

const validation = ref({
  email: { email: true, unique: true },
  firstname: true,
  lastname: true,
  locale_code: true,
  role: true,
  teams: true,
});

const isEditRoleDropdownEnabled = true; // FIXME: implement user limit check on role switch from observer to another role https://linear.app/trengo/issue/WALL-623/implement-user-count-check-edit-user-modal

const tooltipEvent = computed(() => (isMobile ? 'hover' : 'hover focus click'));

const isUserUnverified = computed(() => user.value.status === UserStatus.UNVERIFIED);

const isDirty = computed(
  () => (props.initialUser && !isEqual(props.initialUser, user.value)) || isPrimary.value !== initialIsPrimaryValue,
);

const { getRoles, isTheOnlyAdministrator, isTheOnlyPrimaryUser, userCanBeDeleted } = useRolesDropdown(
  props.initialUser,
);

const isObserver = (role: string) => role === USER_ROLE.OBSERVER;

const roles = computed(() =>
  getRoles({ allowObserver: false }).map((item: RoleItem) => ({
    value: item.value,
    title: t(item.titleKey),
    subtitle: t(item.subtitleKey),
  })),
);

const validateFields = computed(
  () =>
    validation.value.firstname &&
    validation.value.lastname &&
    validation.value.locale_code &&
    validation.value.role &&
    validation.value.teams,
);

const setValidationFields = () => {
  validation.value.firstname = !!user.value.first_name;
  validation.value.lastname = !!user.value.last_name;
  validation.value.locale_code = !!user.value.locale_code;
  validation.value.role = !!user.value.role;
  validation.value.teams = (user.value.teams && user.value.teams?.length > 0) ?? false;
};

const handleKeyboardEvent = (event: KeyboardEvent) => {
  if (event.key === 'Enter') {
    save();
  }
};

const deleteUser = () => emit('delete', user.value);

const save = async () => {
  if (!isDirty.value) {
    closeModal();
    return;
  }

  if (isLoading.value) {
    return;
  }

  isLoading.value = true;
  setValidationFields();

  if (!validateFields.value) {
    isLoading.value = false;
    return;
  }

  await handleEditUser();
  isLoading.value = false;
  closeModal();
};

const mapUserToUpdate = () => {
  const { first_name, last_name, locale_code, email } = user.value;

  return {
    email,
    first_name,
    last_name,
    is_primary: isPrimary.value === 'yes',
    locale_code,
    role: user.value.role.name,
    team_ids: user.value.teams?.map((item) => item.id) ?? [],
  };
};

const updateStores = async (updatedUser: User) => {
  const updatedUserRecord: User = { ...userStore.user, ...updatedUser };
  await usersStore.updateUser(updatedUserRecord);

  // FIXME: remove $root.users when users store migration is complete https://linear.app/trengo/issue/WALL-622/clean-up-dollarrootusers
  vm.$root.users = vm.$root.users.map((item: User) => {
    if (item.id === updatedUser.id) {
      return { ...item, ...updatedUserRecord };
    }
    return item;
  });

  const loggedInUserId = userStore.user?.id;
  if (user.value.id === loggedInUserId) {
    await userStore.updateUser(updatedUserRecord);

    if (userStore.user) {
      identifySegmentUser(userStore.user);
    }
  }
};

const handleEditUser = async () => {
  try {
    const { data: updatedUser } = await editUser(user.value.id, mapUserToUpdate());
    flashSuccess(t('user_management.user_details_were_updated_successfully'));

    emit('update', user);

    updateStores(updatedUser);
  } catch (e) {
    isLoading.value = false;
    console.error('Error while saving user: ', e);
  }
};

const resendInvite = () => {
  sendVerification(user.value.id)
    .then(() => {
      flashSuccess(t('user_management.invite_was_sent_successfully'));
      closeModal();
    })
    .catch((e) => {
      console.error('Error while sending invite: ', e);
    });
};

const closeModal = () => emit('close');

const loadTeams = () => {
  getAllTeams().then(({ data }) => {
    teams.value = data.data;
  });
};

onMounted(() => {
  loadTeams();
});
</script>

<style lang="scss">
@import '@/styles/UserModalTemplate.scss';
</style>
