<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="24" height="24">
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2429 2.74969C13.0197 2.74969 12.8636 2.86648 12.8174 3.06814L11.7676 7.65527C11.601 8.38529 11.0191 8.93926 10.2949 9.09516L5.57193 10.1148C5.12382 10.2115 5.12379 10.7984 5.57189 10.8951L10.2949 11.9148V11.9148C11.0189 12.0708 11.6011 12.6248 11.7676 13.3546L12.8174 17.9417C12.8636 18.1434 13.0197 18.2602 13.2429 18.2602C13.466 18.2602 13.6222 18.1434 13.6683 17.9418L14.7181 13.3546V13.3546C14.8848 12.625 15.4666 12.0708 16.1905 11.9148V11.9148L20.9138 10.8951C21.3619 10.7984 21.362 10.2115 20.9139 10.1148L16.1909 9.09516C15.4668 8.93929 14.885 8.38552 14.7182 7.65572L13.6683 3.06818C13.6222 2.86652 13.466 2.74969 13.2429 2.74969ZM15.1305 2.73358C14.6779 0.755066 11.8079 0.755066 11.3552 2.73358L10.3053 7.32109C10.2728 7.46346 10.1532 7.59129 9.97919 7.62875L5.25543 8.64854V8.64854C3.22931 9.08588 3.22929 11.9241 5.25543 12.3614L9.97876 13.3811C10.1528 13.4185 10.2727 13.546 10.3052 13.6884L11.3552 18.2763C11.8079 20.2548 14.6779 20.2548 15.1305 18.2763L16.1804 13.6888C16.2129 13.5465 16.3326 13.4186 16.5066 13.3812L21.2303 12.3614C23.2565 11.9241 23.2565 9.08589 21.2304 8.64855V8.64855L16.507 7.62884C16.333 7.59138 16.213 7.46392 16.1805 7.32155L15.1305 2.73358Z"
        fill="#C6CACD"
        class="svg-fill"
      />
      <path
        d="M3.8352 17.7785C4.28431 17.6818 4.63507 17.3411 4.73461 16.9049L5.05574 15.5008C5.17068 14.9967 5.91012 14.9967 6.02506 15.5008L6.34619 16.9049C6.44573 17.3411 6.79649 17.6818 7.2456 17.7785L8.69129 18.0904C9.21031 18.202 9.21031 18.9202 8.69129 19.0318L7.2456 19.3437C6.79649 19.4404 6.44573 19.781 6.34619 20.2172L6.02506 21.6214C5.91012 22.1255 5.17068 22.1255 5.05574 21.6214L4.73461 20.2172C4.63507 19.781 4.28431 19.4404 3.8352 19.3437L2.38951 19.0318C1.87049 18.9202 1.87049 18.202 2.38951 18.0904L3.8352 17.7785Z"
        fill="#C6CACD"
        class="svg-fill"
      />
    </g>
    <path
      class="icon-active"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0364 7.48841C10.9368 7.9246 10.5861 8.26527 10.137 8.36195L5.41359 9.38166C4.17647 9.64867 4.17647 11.3612 5.41359 11.6282L10.137 12.648C10.5861 12.7446 10.9368 13.0853 11.0364 13.5215L12.0863 18.109C12.3612 19.3106 14.1244 19.3106 14.3994 18.109L15.4493 13.5215C15.5488 13.0853 15.8996 12.7446 16.3487 12.648L21.072 11.6282C22.3092 11.3612 22.3092 9.64867 21.072 9.38166L16.3487 8.36195C15.8996 8.26527 15.5488 7.9246 15.4493 7.48841L14.3994 2.90086C14.1244 1.69931 12.3612 1.69931 12.0863 2.90086L11.0364 7.48841ZM4.73455 16.9049C4.63501 17.3411 4.28425 17.6818 3.83514 17.7785L2.38945 18.0904C1.87043 18.202 1.87043 18.9202 2.38945 19.0318L3.83514 19.3437C4.28425 19.4404 4.63501 19.7811 4.73455 20.2173L5.05568 21.6214C5.17062 22.1255 5.91006 22.1255 6.025 21.6214L6.34613 20.2173C6.44567 19.7811 6.79643 19.4404 7.24554 19.3437L8.69123 19.0318C9.21025 18.9202 9.21025 18.202 8.69123 18.0904L7.24554 17.7785C6.79643 17.6818 6.44567 17.3411 6.34613 16.9049L6.025 15.5008C5.91006 14.9967 5.17062 14.9967 5.05568 15.5008L4.73455 16.9049Z"
      fill="white"
    />
  </svg>
</template>
