<template>
  <div>
    <draggable v-model="buttonSections" handle=".drag-handle" ghost-class="drag-ghost" @end="handleReorder">
      <div v-for="(element, idx) in buttonSections" :key="idx">
        <div v-if="element.section === WA_TEMPLATE_COMPONENT_SUB_TYPE.URL" :class="[idx === 0 ? 'mb-4' : '']">
          <!-- Action Buttons -->
          <div v-if="showActionButtons" class="bg-grey-200 py-4 px-4 rounded-lg">
            <div class="flex items-center mb-2">
              <div v-if="isCreateMode" class="drag-handle cursor-grab flex items-center">
                <reorder-fill class="text-grey-600" size="1.25rem" />
              </div>
              <p class="text-grey-600 t-text-sm-emphasize mb-0" :class="[isCreateMode ? 'ml-2' : '']">
                {{ $t('whatsapp.template_action_buttons') }}
              </p>
            </div>
            <!-- URL TEXT Input -->
            <div :class="[isCreateMode ? 'ml-8' : '']">
              <div
                v-for="(btn, index) in buttonsData[WA_TEMPLATE_COMPONENT_SUB_TYPE.URL]"
                :key="btn.id"
                class="flex justify-between gap-4 mb-2"
              >
                <div :class="[isCreateMode ? 'w-5/12' : 'w-6/12']" data-test="url-text-input-container">
                  <t-input-text
                    id="url-text-input"
                    ref="url-text-input"
                    v-model="btn.value"
                    :autofocus="isCreateMode"
                    :disabled="btn.value.length > MAX_BUTTON_TEXT_LENGTH || !isCreateMode"
                    :has-error="!btn.isValid"
                    :label="$t('whatsapp.template_button_text')"
                    :max="characterCount(btn.value)"
                    :maxlength="MAX_BUTTON_TEXT_LENGTH"
                    :placeholder="$t('whatsapp.template_enter_button_text')"
                    data-test="url-text-input"
                    @input="
                      $emit('handle-button-input-change', {
                        subType: WA_TEMPLATE_COMPONENT_SUB_TYPE.URL,
                        btnInputType: WA_TEMPLATE_BUTTON_INPUT_TYPE.VALUE,
                      })
                    "
                  >
                    <template #end>
                      <t-badge :text="characterCount(btn.value)" />
                    </template>
                  </t-input-text>
                  <t-error-item class="mt-1" data-test="wa-input-error" :text="btn.error.VALUE" />
                </div>
                <!-- URL LINK Input -->
                <div class="w-6/12" data-test="url-link-input">
                  <t-input-text
                    id="url-link-input"
                    v-model="btn.details"
                    :autofocus="isCreateMode"
                    :label="$t('whatsapp.template_button_url')"
                    :maxlength="MAX_URL_LINK_LENGTH"
                    :disabled="!isCreateMode"
                    :placeholder="$t('whatsapp.template_enter_URL')"
                    type="text"
                    data-test="url-link-input"
                    @input="
                      $emit('handle-button-input-change', {
                        subType: WA_TEMPLATE_COMPONENT_SUB_TYPE.URL,
                        btnInputType: WA_TEMPLATE_BUTTON_INPUT_TYPE.DETAILS,
                      })
                    "
                  >
                    <template #prefix>
                      <span>https://</span>
                    </template>
                  </t-input-text>
                  <t-error-item class="mt-1" data-test="wa-input-error" :text="btn.error.DETAILS" />
                </div>
                <!-- Popup Menu -->
                <div v-if="isCreateMode" class="w-1/12 mt-6" data-test="popup-container">
                  <popup-menu
                    action-text="Delete"
                    @handle-click="
                      $emit('handle-delete-button', { subType: WA_TEMPLATE_COMPONENT_SUB_TYPE.URL, index })
                    "
                  ></popup-menu>
                </div>
              </div>
              <t-button
                v-if="showAddButton"
                class="flex justify-between items-center mt-2"
                btn-style="secondary"
                type="button"
                :disabled="isAddUrlButtonDisabled"
                @click="$emit('add-new-button', WA_TEMPLATE_COMPONENT_SUB_TYPE.URL)"
              >
                <plus-linear class="text-grey-500" size="1.25rem" />
                {{ $t('whatsapp.template_add_url_button') }}
              </t-button>
            </div>
          </div>
        </div>
        <div v-if="element.section === WA_TEMPLATE_COMPONENT_SUB_TYPE.QUICK_REPLY" :class="[idx === 0 ? 'mb-4' : '']">
          <!-- Text Buttons -->
          <div v-if="showTextButtons" class="bg-grey-200 py-4 px-4 rounded-lg">
            <div class="flex items-center mb-2">
              <div v-if="isCreateMode" class="drag-handle cursor-grab flex items-center">
                <reorder-fill class="text-grey-600" size="1.25rem" />
              </div>
              <p class="text-grey-600 t-text-sm-emphasize mb-0" :class="[isCreateMode ? 'ml-2' : '']">
                {{ $t('whatsapp.template_text_buttons') }}
              </p>
            </div>
            <!-- QUICK REPLY TEXT Input -->
            <div :class="[isCreateMode ? 'ml-8' : '']">
              <div
                v-for="(btn, index) in buttonsData[WA_TEMPLATE_COMPONENT_SUB_TYPE.QUICK_REPLY]"
                :key="btn.id"
                class="flex justify-between gap-4 mb-2"
              >
                <div :class="[isCreateMode ? 'w-11/12' : 'w-full']" data-test="quick-reply-input-container">
                  <t-input-text
                    id="quick-reply-input"
                    ref="quick-reply-text-input"
                    v-model="btn.value"
                    :autofocus="isCreateMode"
                    :disabled="btn.value.length > MAX_BUTTON_TEXT_LENGTH || !isCreateMode"
                    :has-error="!btn.isValid"
                    :label="$t('whatsapp.template_button_text')"
                    :max="characterCount(btn.value)"
                    :maxlength="MAX_BUTTON_TEXT_LENGTH"
                    :placeholder="$t('whatsapp.template_enter_button_text')"
                    type="text"
                    data-test="quick-reply-input"
                    @input="
                      $emit('handle-button-input-change', {
                        subType: WA_TEMPLATE_COMPONENT_SUB_TYPE.QUICK_REPLY,
                        btnInputType: WA_TEMPLATE_BUTTON_INPUT_TYPE.VALUE,
                      })
                    "
                  >
                    <template #end>
                      <t-badge :text="characterCount(btn.value)" />
                    </template>
                  </t-input-text>
                  <t-error-item class="mt-1" data-test="wa-input-error" :text="btn.error.VALUE" />
                </div>
                <!-- Popup Menu -->
                <div v-if="isCreateMode" class="w-1/12 mt-6" data-test="quick-reply-popup-container">
                  <popup-menu
                    :action-text="$t('whatsapp.template_delete_button')"
                    @handle-click="
                      $emit('handle-delete-button', { subType: WA_TEMPLATE_COMPONENT_SUB_TYPE.QUICK_REPLY, index })
                    "
                  ></popup-menu>
                </div>
              </div>
              <t-button
                v-if="showAddButton"
                class="flex justify-between items-center mt-2"
                btn-style="secondary"
                type="button"
                :disabled="isAddQuickReplyButtonDisabled"
                @click="$emit('add-new-button', WA_TEMPLATE_COMPONENT_SUB_TYPE.QUICK_REPLY)"
              >
                <plus-linear class="text-grey-500" size="1.25rem" />
                {{ $t('whatsapp.template_add_text_button') }}
              </t-button>
            </div>
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script lang="ts">
import { PlusLinear, ReorderFill } from '@trengo/trengo-icons';
import { VueDraggableNext } from 'vue-draggable-next';

import {
  WA_TEMPLATE_COMPONENT_SUB_TYPE,
  MAX_TEMPLATE_BUTTON_COUNT,
  WA_TEMPLATE_BUTTON_INPUT_TYPE,
  TEMPLATE_ACTION,
  MAX_BUTTON_TEXT_LENGTH,
  MAX_URL_LINK_LENGTH,
} from '@/Configs/Constants/whatsapp';

import PopupMenu from '../PopupMenu';

import type { TemplateButton, TemplateButtonSection } from '../types';
import type { PropType } from 'vue';

export default {
  name: 'WaTemplateButtons',
  components: { PopupMenu, PlusLinear, draggable: VueDraggableNext, ReorderFill },
  props: {
    buttonsData: {
      type: Object as PropType<TemplateButton>,
      default: () => ({}),
    },
    mode: {
      type: String,
      default: '',
    },
  },
  emits: [
    'handle-button-input-change',
    'handle-delete-action-button',
    'update-quick-reply-button-value',
    'handle-delete-quick-reply-button',
    'handle-delete-button',
    'add-new-button',
    'reorder-button-sections',
  ],
  data() {
    return {
      WA_TEMPLATE_COMPONENT_SUB_TYPE,
      WA_TEMPLATE_BUTTON_INPUT_TYPE,
      MAX_BUTTON_TEXT_LENGTH,
      MAX_URL_LINK_LENGTH,
      createModeButtonSections: [
        { section: WA_TEMPLATE_COMPONENT_SUB_TYPE.URL },
        { section: WA_TEMPLATE_COMPONENT_SUB_TYPE.QUICK_REPLY },
      ] as TemplateButtonSection[],
    };
  },
  computed: {
    buttonSections: {
      get() {
        return this.isCreateMode ? this.createModeButtonSections : this.editModebuttonSections;
      },
      set(value: TemplateButtonSection[]) {
        if (!this.isCreateMode) return;
        this.createModeButtonSections = value;
      },
    },
    editModebuttonSections(): TemplateButtonSection[] {
      return Object.keys(this.buttonsData).map((key) => ({ section: key })) as TemplateButtonSection[];
    },
    isAddQuickReplyButtonDisabled() {
      return (
        this.buttonsData[WA_TEMPLATE_COMPONENT_SUB_TYPE.QUICK_REPLY].length === MAX_TEMPLATE_BUTTON_COUNT.QUICK_REPLY
      );
    },
    isAddUrlButtonDisabled() {
      return this.buttonsData[WA_TEMPLATE_COMPONENT_SUB_TYPE.URL].length === MAX_TEMPLATE_BUTTON_COUNT.URL;
    },
    isCreateMode() {
      return this.mode === TEMPLATE_ACTION.CREATE;
    },
    showAddButton() {
      return this.isCreateMode;
    },
    showActionButtons() {
      return (
        this.isCreateMode || (!this.isCreateMode && this.buttonsData[WA_TEMPLATE_COMPONENT_SUB_TYPE.URL].length > 0)
      );
    },
    showTextButtons() {
      return (
        this.isCreateMode ||
        (!this.isCreateMode && this.buttonsData[WA_TEMPLATE_COMPONENT_SUB_TYPE.QUICK_REPLY].length > 0)
      );
    },
  },
  methods: {
    characterCount(text: string): string {
      return `${text.length}/${MAX_BUTTON_TEXT_LENGTH}`;
    },
    handleReorder(): void {
      this.$emit('reorder-button-sections', this.buttonSections);
    },
  },
};
</script>

<style lang="scss" scoped>
.drag-ghost {
  @apply opacity-50;
}

.sortable-drag {
  @apply bg-white border-1 border-solid border-grey-300 shadow-md rounded-lg py-2;
}

.sortable-chosen * {
  @apply bg-white visible;
}
</style>
