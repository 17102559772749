import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

import { API_INBOX_AI } from '@/components/ReplyForm/api/const';
import { useModal } from '@/composables/useModal';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';
import { request } from '@/util';

import type { IsSuggestedReplyAvailableResponse, SuggestedReplyResponse } from './types';

export const useSuggestedReplyStore = defineStore('suggestedReply', () => {
  const featureFlagStore = useFeatureFlagStore();
  const isLoading = ref(false);
  const isError = ref(false);
  const isActive = ref(false); // has the right to use the feature

  const modal = useModal();
  const isHidden = ref(false);

  const suggestedReply = ref('');
  const activeTicketId = ref<number>();
  const activeChannelId = ref<string | number>('');
  const isFeatureEnabled = computed(() => featureFlagStore.isEnabled(FEATURE_FLAG_INBOX.TI_SUGGESTED_REPLY));
  const isFeedbackActive = ref(false);

  watch(activeTicketId, (ticketId, oldTicketId) => {
    if (ticketId !== oldTicketId) isError.value = false;
  });

  async function getIsSuggestedReplyAvailable(channelId?: string | number) {
    if (!isFeatureEnabled.value || channelId === activeChannelId.value) return;
    if (channelId) activeChannelId.value = channelId;
    else return;

    try {
      const { data } = await axios.get<IsSuggestedReplyAvailableResponse>(
        `${API_INBOX_AI}/support_suggested_reply?channel_id=${channelId}`,
      );
      isActive.value = !!data?.is_channel_supported;
    } catch {
      return; // We don't want to show the user that they're not able to use the feature
    }
  }

  async function updateReply(ticketId?: number) {
    if (!ticketId || !isFeatureEnabled.value) return;
    isError.value = false;
    isLoading.value = true;

    try {
      const res = await request<SuggestedReplyResponse>(
        `${API_INBOX_AI}/suggested_reply?ticket_id=${ticketId}`,
        'POST',
      );
      suggestedReply.value = res.data.response;
    } catch {
      isError.value = true;
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    isError,
    isActive,
    modal,
    isHidden,
    suggestedReply,
    activeTicketId,
    activeChannelId,
    isFeatureEnabled,
    isFeedbackActive,
    getIsSuggestedReplyAvailable,
    updateReply,
  };
});
