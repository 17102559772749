import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wa-template-btn" }
const _hoisted_2 = {
  key: 0,
  class: "wa-template-btn__input-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_title = _resolveComponent("form-title")!
  const _component_wa_template_buttons = _resolveComponent("wa-template-buttons")!
  const _component_t_button = _resolveComponent("t-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_title, {
      modelValue: _ctx.computedValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event)),
      "badge-text": _ctx.badgeText,
      "is-toggle-disabled": _ctx.isToggleDisabled,
      "is-text-disabled": _ctx.isTitleTextDisabled,
      "heading-text": _ctx.headingText,
      "should-show-badge": _ctx.shouldShowBadge,
      "should-show-switch": _ctx.shouldShowSwitch,
      subtitle: _ctx.subtitle,
      "tooltip-text": _ctx.tooltipText
    }, null, 8 /* PROPS */, ["modelValue", "badge-text", "is-toggle-disabled", "is-text-disabled", "heading-text", "should-show-badge", "should-show-switch", "subtitle", "tooltip-text"]),
    (_ctx.computedValue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_wa_template_buttons, {
            "buttons-data": _ctx.buttonsData,
            mode: _ctx.mode,
            onAddNewButton: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('add-new-button', $event))),
            onHandleDeleteButton: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('handle-delete-button', $event))),
            onHandleButtonInputChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('handle-button-input-change', $event))),
            onReorderButtonSections: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('reorder-button-sections', $event)))
          }, null, 8 /* PROPS */, ["buttons-data", "mode"])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.showActionButton)
      ? (_openBlock(), _createBlock(_component_t_button, {
          key: 1,
          disabled: _ctx.disableMigrationButton,
          type: "button",
          size: "sm",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('go-to-migration')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.btnText), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"]))
      : _createCommentVNode("v-if", true)
  ]))
}