<script>
import { sanitize } from 'dompurify';

export default {
  name: 'QuotedEmailMessage',
  props: {
    html: {
      type: String,
      default: '',
    },
    message: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted() {
    this.parse();
  },

  watch: {
    html() {
      this.parse();
    },
  },

  data() {
    return {
      isOutlook: false,
    };
  },

  methods: {
    parse() {
      this.$nextTick(() => {
        if (!this.$refs.container) {
          return;
        }
        let quote;
        this.$refs.container.querySelectorAll('blockquote').forEach((blockquote, i) => {
          if (i > 2 || this.isGmailIndent(blockquote)) return;
          this.collapse(blockquote);
          if (i === 0) {
            quote = blockquote;
          }
        });
        if (quote) {
          let click = this.renderToggleElement();
          this.$refs.container.appendChild(click);
          click.addEventListener('click', () => {
            if (quote.style.display === 'none') {
              this.expand(quote);
            } else {
              this.collapse(quote);
            }
          });
        }
      });
    },

    insertAfter(newNode, referenceNode) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    },

    expand() {
      this.$refs.container.querySelectorAll('blockquote').forEach((blockquote, i) => {
        if (i > 2 || this.isGmailIndent(blockquote)) return;
        blockquote.style.height = 'auto';
        blockquote.style.overflow = 'visible';
        blockquote.style.marginBottom = '10px';
        blockquote.style.display = 'block';
      });
    },

    collapse() {
      this.$refs.container.querySelectorAll('blockquote').forEach((blockquote, i) => {
        if (i > 2 || this.isGmailIndent(blockquote)) return;
        blockquote.style.overflow = 'hidden';
        blockquote.style.marginBottom = 0;
        blockquote.style.marginTop = 0;
        blockquote.style.display = 'none';
      });
    },

    renderToggleElement() {
      let el = document.createElement('div');
      el.innerHTML =
        '<i class="material-icons select-none mt-2 text-xl  text-grey-600 text-lg cursor-pointer hover:text-grey-800 bg-grey-200 rounded-lg  px-2 ">more_horiz</i>';
      return el;
    },

    parseMessage(html) {
      // Encode email addresses to prevent them from being stripped by sanitizer when From: Name <email> is used
      html = html.replace(/\<([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)\>/gi, '&lt;$1&gt;');

      var parser = new DOMParser();
      var doc = parser.parseFromString(html, 'text/html');
      var paragraphs = doc.querySelectorAll('img');
      paragraphs.forEach((img) => {
        img.src = `${IMAGE_PROXY_URL}?url=${encodeURIComponent(img.src)}`;
      });

      var blockquotes = doc.querySelectorAll('blockquote');
      blockquotes.forEach((blockquote, i) => {
        if (i > 10) {
          // render a maximum of 10 blockquotes
          blockquote.parentNode.removeChild(blockquote);
        }
      });

      html = doc.querySelector('body').innerHTML;
      html = sanitize(html, {
        FORBID_TAGS: ['style', 'svg', 'audio', 'video', 'math', 'meta', 'script'],
        FORBID_ATTR: ['class'],
      });

      if (html == null) {
        return html;
      }

      html = window.linkifyHtml(html);

      if (html.includes('MsoNormal')) {
        // Clean up for legacy emails
        let cleanup = [
          '<p class="MsoNormal"><span style="font-size:10pt;font-family:Tahoma, sans-serif;"></span></p>',
          '<p></p>',
          '<p>&nbsp;</p>',
          '<p> </p>',
          '<p class="MsoNormal"></p>',
          '<div></div>',
          '<br><br><br>',
          '<br /><br /><br />',
          '<div style="font-family:Calibri, Arial, Helvetica, sans-serif;font-size:12pt;color:rgb(0,0,0);"><br><br></div>',
        ];
        cleanup.forEach((clean) => {
          html = html.replaceAll(clean, '');
        });

        // Get rid of that Outlook blue
      }

      return html;
    },
    isGmailIndent(blockquote) {
      const { marginLeft, border, padding } = blockquote.style;
      return marginLeft === '40px' && border === 'none' && padding === '0px';
    },

    replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
    },
  },
};
</script>

<template>
  <div
    ref="container"
    class="e"
    style="overflow-x: scroll; overflow-y: hidden; position: relative"
    :class="{ 'is-outlook-mail': isOutlook, 'is-inbound-mail': message.type === 'INBOUND' }"
    v-html="parseMessage(html)"
  ></div>
</template>

<style lang="scss">
.is-inbound-mail p {
  /*margin-bottom: 0 !important;*/
}

.mail-message {
  // Some email clients apply position absolute to divs
  div:not(.dropdown-menu) {
    position: relative !important;
    top: initial !important;
    right: initial !important;
    left: initial !important;
    bottom: initial !important;
  }

  .email-body {
    table {
      max-width: 700px !important;

      tr {
        border-bottom-width: 0 !important;
      }
    }

    p,
    span,
    table,
    td,
    hr,
    div,
    th,
    strong,
    b,
    u,
    em {
      font-family: 'Inter', 'Arial', sans-serif;
      font-size: 15px !important;
    }

    p {
      line-height: 22px !important;
    }

    blockquote {
      padding-left: 0.65em !important;
      border-left: 2px solid currentColor !important;
      color: #89a1b6 !important;
      margin: 0;

      * {
        color: #89a1b6 !important;
      }

      &[style='margin:0 0 0 40px;border:none;padding:0px'] {
        padding-left: unset !important;
        border-left: unset !important;
        color: unset !important;

        * {
          color: unset !important;
        }
      }
    }
  }

  img {
    max-width: 100% !important;
  }

  table {
    @media (max-width: 700px) {
      float: left;
      width: 100% !important;
    }
  }
}
</style>
