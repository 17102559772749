<template>
  <div
    class="flex justify-end"
    data-test="chatNoteWrapper"
    @mouseover="isNoteDeleteVisible = true"
    @mouseleave="isNoteDeleteVisible = false"
  >
    <div class="flex w-full max-w-full flex-col items-end">
      <div class="note-text-wrapper">
        <div class="relative min-h-12 rounded-lg border-2 border-sun-300 bg-sun-100 p-3 px-12 pl-6">
          <div class="avatar-container">
            <avatar
              v-if="message.agent"
              width="45"
              class="avatar"
              :border="true"
              :color="message.agent.color"
              :abbr="message.agent.abbr"
              :image="message.agent.profile_image"
            />
            <avatar v-else class="avatar" color="#5bb130" abbr="T" />
          </div>

          <div>
            <div v-if="isOnlyEmoji(message.message) && messageLength < 4" data-test="chatNoteMessageWrapper">
              <span class="text-3xl" data-test="chatNoteMessage">
                {{ message.message }}
              </span>

              <div v-if="message.attachments.length > 0" class="flex flex-col" style="min-width: 250px">
                <file-count
                  :attachment-count="message.attachments.length"
                  :are-attachments-visible="areAttachmentsVisible"
                  @toggle-attachments="toggleAttachments"
                />

                <span v-if="areAttachmentsVisible">
                  <message-attachment
                    v-for="attachment in message.attachments"
                    :key="attachment.id"
                    :attachment="attachment"
                    :is-deletable="false"
                  />
                </span>
              </div>
            </div>

            <div v-else class="flex flex-col">
              <div class="mb-2 flex items-center">
                <div v-for="mention in message.mentions" :key="mention.id">
                  <span
                    v-if="mention && mention.user_id === $root.user.id"
                    class="mention cursor-pointer"
                    data-test="chatNoteMentionToggleable"
                    @click="toggleSeen(mention)"
                  >
                    <i class="material-icons" :class="{ 'text-grey-600': !mention.seen, 'text-green': mention.seen }">
                      check_circle
                    </i>
                  </span>

                  <span v-else-if="mention" class="mention opacity-30" data-test="chatNoteMentionStatic">
                    <i class="material-icons" :class="{ 'text-grey-600': !mention.seen, 'text-green': mention.seen }">
                      check_circle
                    </i>
                  </span>
                </div>

                <div id="message-container" v-html="parseMention(message.message)" />
              </div>

              <div v-if="message.attachments.length > 0" class="flex flex-col" style="min-width: 250px">
                <file-count
                  :attachment-count="message.attachments.length"
                  :are-attachments-visible="areAttachmentsVisible"
                  @toggle-attachments="toggleAttachments"
                />

                <span v-if="areAttachmentsVisible">
                  <message-attachment
                    v-for="attachment in message.attachments"
                    :key="attachment.id"
                    :attachment="attachment"
                    :is-deletable="false"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-2 flex flex-nowrap items-center text-sm text-grey-600">
        <span
          v-if="message.agent && $root.user.id === message.agent.id"
          class="mr-1.5 flex-shrink-0 cursor-pointer text-xs"
          :class="{ 'opacity-0': !isNoteDeleteVisible }"
          @click="deleteMessage"
        >
          <span class="text-info">{{ $t('general.delete') }}</span>
          <span class="mx-1">-</span>
        </span>

        <span v-if="message.agent && message.agent.first_name" class="flex-shrink text-ellipsis">
          {{ message.agent.first_name }} {{ message.agent.last_name }}
        </span>

        <span v-if="message.agent" class="mx-1">-</span>

        <span class="flex-shrink-0 text-ellipsis">
          <datetime :time="message.created_at" :pretty="$root.prettyDates" />
        </span>

        <span class="flex-shrink-0 pl-2">
          <note-text-linear size="1.1rem" />
        </span>

        <TranslateLink
          class="ms-2"
          :can-revert="
            inboundTranslationStore?.getIsStorageTranslationAvailable({
              messageId: message.id,
              ticketId: message.ticket_id,
              message: message.message,
              locale: userStore?.user?.locale_code,
            })
          "
          :status="inboundTranslationStore.status"
          @translate="(isReverting: boolean) => $emit('translate-note', isReverting)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { NoteTextLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import Avatar from '@/components/Avatar.vue';
import { putToggleSeen } from '@/components/MessageFeed/api';
import FileCount from '@/components/MessageFeed/FileCount.vue';
import MessageAttachment from '@/components/MessageFeed/MessageAttachment.vue';
import { isOnlyEmoji, escapeHtml } from '@/util/stringHelpers';
import taggable from '@/util/Taggable';
import TranslateLink from '@/components/common/TranslateLink/TranslateLink.vue';

import type { FeedMessage } from '@/types/message';
import type { PropType } from 'vue';
import { mapStores } from 'pinia';
import { useInboundTranslationStore } from '@/store/pinia/translation/useInboundTranslationStore';
import { useUserStore } from '@/store/pinia';

export default defineComponent({
  name: 'Note',
  emits: ['deleteTicketMessage', 'translate-note'],
  props: {
    message: {
      type: Object as PropType<FeedMessage>,
      default: () => ({}),
    },
    messages: {
      type: Array as PropType<FeedMessage[]>,
      default: () => [],
    },
  },

  components: {
    Avatar,
    FileCount,
    MessageAttachment,
    NoteTextLinear,
    TranslateLink,
  },

  data() {
    return {
      isNoteDeleteVisible: true,
      areAttachmentsVisible: true,
    };
  },

  computed: {
    ...mapStores(useUserStore, useInboundTranslationStore),
    // Emoji can (and most commonly are) comprised of a combination of multiple different unicode characters combined with a zero-width-joiner character.
    // As such, to get the true length of a string containing emoji, we have to extract individual codepoints. This is easily done by spreading the string
    // and only checking the length of that spread array, rather than the string itself.
    messageLength() {
      return [...this.message.message].length;
    },
  },

  methods: {
    isOnlyEmoji,

    toggleAttachments() {
      this.areAttachmentsVisible = !this.areAttachmentsVisible;
    },

    parseMention(text) {
      text = text.replace('<br>', '\n');
      text = window.stripHtml(text);
      text = escapeHtml(text);
      text = window.linkifyHtml(text);
      text = text.replace(/\n/g, '<br />');

      return text.replace(/@([a-z0-9_]+)/g, (match) => {
        let user = this.$root.users.find((u) => taggable.userToMention(u) === match.trim());
        return user
          ? `<span class="text-info select-none"><span class="text-grey-600">@</span>${escapeHtml(
              user.full_name,
            )}</span>`
          : match;
      });
    },

    toggleSeen(mention) {
      mention.seen = !mention.seen;
      putToggleSeen(mention.id, mention.seen);

      if (mention.seen) {
        this.markPreviousTasks();
      }
    },

    markPreviousTasks() {
      const previousNotes = this.messages.filter((message) => {
        return message.type === 'NOTE' && message.mentions?.length > 0 && message.id < this.message.id;
      });

      if (!previousNotes) {
        return;
      }

      previousNotes.forEach((note) => {
        note.mentions
          .filter((mention) => mention.user_id === this.$root.user.id)
          .forEach((mention) => {
            mention.seen = true;
          });
      });
    },

    deleteMessage() {
      this.$emit('deleteTicketMessage', this.message.id);
    },
  },
});
</script>

<style lang="scss" scoped>
#message-container :deep(> .linkified) {
  @apply underline;
}

.note-text-wrapper {
  @apply break-words;
  word-break: break-word;
  max-width: 80%;
}

.avatar-container {
  @apply absolute cursor-pointer overflow-hidden rounded-lg;
  right: -2px;
  top: -2px;
  width: 40px;
  height: 40px;
}

.avatar {
  @apply absolute border-sun-300;
  right: -5px;
  top: -5px;
}

.mention {
  @apply -ml-2 mr-2 inline-block flex items-center rounded-full;
}

@media screen and (min-width: 1920px) {
  .note-text-wrapper {
    max-width: 768px;
  }
}
</style>
