import { capitalize } from 'lodash';

import { SHORT_MONTH_LIST } from '@/Configs/Constants';

import { FULL_BAR_LENGTH } from './constants';

import type { BarValues } from './types';

export const formatAmount = (format: 'currency' | 'number', amount: number) => {
  const locale = 'nl-NL';
  const Euro = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
  });
  switch (format) {
    case 'currency':
      return Euro.format(amount / 100);
    case 'number':
      return amount.toLocaleString(locale, {
        style: 'decimal',
      });
    default:
      return amount;
  }
};

export const generateChartLabels = (month: string) => {
  const startingMonthIndex = SHORT_MONTH_LIST.findIndex((item) => item === month.toLowerCase());
  return Array.from({ length: 12 }, (_, i) => {
    const monthIndex = (startingMonthIndex + i) % 12;
    return capitalize(SHORT_MONTH_LIST[monthIndex]);
  });
};

export const calculateDividedBarValues = (conversationValues: {
  consumption: number;
  inProgressAmount: number;
  commitment: number;
}): BarValues[] => {
  const { consumption, inProgressAmount, commitment } = conversationValues;
  if (commitment < 0 || inProgressAmount < 0 || consumption < 0) {
    return [];
  }

  if (commitment === 0) {
    return [{ ...conversationValues, barLength: FULL_BAR_LENGTH }];
  }

  const isOverCommitment = consumption > commitment;
  const totalConversations = consumption + inProgressAmount;
  const oneBarLength = totalConversations / FULL_BAR_LENGTH;

  const firstBarLength = Math.max(1, Math.floor(commitment / oneBarLength));

  const firstBarValues: BarValues = {
    consumption: isOverCommitment ? commitment : consumption,
    inProgressAmount: isOverCommitment ? 0 : commitment - consumption,
    commitment: commitment,
    barLength: firstBarLength,
  };

  const secondBarCommitment = totalConversations - commitment;
  const secondBarConsumption = consumption - firstBarValues.consumption;

  const secondBarValues: BarValues = {
    consumption: secondBarConsumption,
    inProgressAmount: secondBarCommitment - secondBarConsumption,
    commitment: secondBarCommitment,
    barLength: FULL_BAR_LENGTH - firstBarLength,
  };

  return [firstBarValues, secondBarValues];
};
