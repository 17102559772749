import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "b-l flex flex-col items-center" }
const _hoisted_2 = {
  class: "scroll-on-hover pl-4",
  style: {"overflow-x":"hidden"}
}
const _hoisted_3 = { class: "flex flex-col py-4 text-center" }
const _hoisted_4 = {
  key: 0,
  class: "t-text-xs m-0 my-1 p-0"
}
const _hoisted_5 = {
  key: 1,
  class: "t-text-xs m-0 my-1 p-0"
}
const _hoisted_6 = {
  key: 2,
  class: "t-text-xs m-0 my-1 p-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_indicator = _resolveComponent("user-indicator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        ($options.onlineUsers.length > 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('sidebar.status_online')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.onlineUsers, (user) => {
          return (_openBlock(), _createBlock(_component_user_indicator, {
            key: user.id,
            class: "mb-1",
            user: user
          }, null, 8 /* PROPS */, ["user"]))
        }), 128 /* KEYED_FRAGMENT */)),
        ($options.awayUsers.length > 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('sidebar.status_away')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.awayUsers, (user) => {
          return (_openBlock(), _createBlock(_component_user_indicator, {
            key: user.id,
            class: "mb-1",
            user: user
          }, null, 8 /* PROPS */, ["user"]))
        }), 128 /* KEYED_FRAGMENT */)),
        ($options.offlineUsers.length > 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('sidebar.status_offline')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.offlineUsers, (user) => {
          return (_openBlock(), _createBlock(_component_user_indicator, {
            key: user.id,
            class: "mb-1",
            user: user
          }, null, 8 /* PROPS */, ["user"]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}