<template>
  <div>
    <settings-landing
      :title="$t('email_settings.email_landing_page_title')"
      :icon="`${$root.assetsURL}img/settings-landing/email.svg`"
      :badge="$t('email_settings.email_landing_page_badge_title')"
      :list="listItems"
    >
      <t-button
        size="lg"
        aria-label="Connect Email"
        type="button"
        label="Button"
        class="mb-4 mr-0 xl:mb-0 xl:mr-4"
        @click="showEmailModal()"
      >
        {{ $t('email_settings.email_landing_page_button_connect_email') }}
      </t-button>
      <t-button
        href="https://help.trengo.com/article/connect-to-any-email-provider"
        target="_blank"
        size="lg"
        btn-style="secondary"
        aria-label="Learn more"
        type="button"
        label="Button"
        class="open-external"
      >
        {{ $t('email_settings.email_landing_page_button_learn_more') }}
      </t-button>
    </settings-landing>
    <create-email-modal-new-view v-if="isNewEmailChannelView" v-model="modalOpen" />
    <create-email-modal v-else />
    <channel-created-modal />
  </div>
</template>

<script lang="ts">
import { mapStores } from 'pinia';

import ChannelCreatedModal from '@/components/Channels/ChannelCreatedModal/ChannelCreatedModal';
import CreateEmailModalNewView from '@/components/Channels/CreateEmailModalNewView.vue';
import SettingsLanding from '@/components/common/SettingsLanding';
import { FEATURE_FLAG_VOLUME } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

export default {
  name: 'EmailLandingPage',
  components: { CreateEmailModalNewView, ChannelCreatedModal, SettingsLanding },
  data() {
    return {
      listItems: [
        this.$t('email_settings.email_landing_page_list_item_easy_automate'),
        this.$t('email_settings.email_landing_page_list_item_set_up_both'),
        this.$t('email_settings.email_landing_page_list_item_able_to_connect'),
      ],
      modalOpen: false,
    };
  },
  methods: {
    showEmailModal() {
      this.modalOpen = this.isNewEmailChannelView;
      if (!this.isNewEmailChannelView) {
        $('#CreateEmailModal').modal('show');
      }
    },
  },
  computed: {
    ...mapStores(useFeatureFlagStore),
    isNewEmailChannelView() {
      return this.featureFlagStore.isEnabled(FEATURE_FLAG_VOLUME.TVE_NYLAS_DEPRECATE_CHANNEL_PROVIDERS);
    },
  },
};
</script>

<style scoped lang="scss" src="./EmailLandingPage.scss" />
