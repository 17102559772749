import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_button = _resolveComponent("t-button")!
  const _component_settings_landing = _resolveComponent("settings-landing")!
  const _component_create_email_modal_new_view = _resolveComponent("create-email-modal-new-view")!
  const _component_create_email_modal = _resolveComponent("create-email-modal")!
  const _component_channel_created_modal = _resolveComponent("channel-created-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_settings_landing, {
      title: _ctx.$t('email_settings.email_landing_page_title'),
      icon: `${_ctx.$root.assetsURL}img/settings-landing/email.svg`,
      badge: _ctx.$t('email_settings.email_landing_page_badge_title'),
      list: $data.listItems
    }, {
      default: _withCtx(() => [
        _createVNode(_component_t_button, {
          size: "lg",
          "aria-label": "Connect Email",
          type: "button",
          label: "Button",
          class: "mb-4 mr-0 xl:mb-0 xl:mr-4",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.showEmailModal()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('email_settings.email_landing_page_button_connect_email')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_t_button, {
          href: "https://help.trengo.com/article/connect-to-any-email-provider",
          target: "_blank",
          size: "lg",
          "btn-style": "secondary",
          "aria-label": "Learn more",
          type: "button",
          label: "Button",
          class: "open-external"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('email_settings.email_landing_page_button_learn_more')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "icon", "badge", "list"]),
    ($options.isNewEmailChannelView)
      ? (_openBlock(), _createBlock(_component_create_email_modal_new_view, {
          key: 0,
          modelValue: $data.modalOpen,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.modalOpen) = $event))
        }, null, 8 /* PROPS */, ["modelValue"]))
      : (_openBlock(), _createBlock(_component_create_email_modal, { key: 1 })),
    _createVNode(_component_channel_created_modal)
  ]))
}