import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7e6671c1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup__content" }
const _hoisted_2 = { class: "popup__content--inner-wrapper" }
const _hoisted_3 = { class: "popup__content--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_icon_button = _resolveComponent("t-icon-button")!
  const _component_trash_linear = _resolveComponent("trash-linear")!
  const _component_t_button_text = _resolveComponent("t-button-text")!
  const _component_t_action_menu = _resolveComponent("t-action-menu")!

  return (_openBlock(), _createBlock(_component_t_action_menu, {
    modelValue: $data.isOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.isOpen) = $event)),
    class: "popup",
    width: "max-content",
    position: $props.position,
    fade: ""
  }, {
    trigger: _withCtx(() => [
      _renderSlot(_ctx.$slots, "button", {}, () => [
        _createVNode(_component_t_icon_button)
      ], true)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "content", {}, () => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_t_button_text, {
              type: $props.btnType,
              "btn-style": "secondary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handle-click')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_trash_linear, {
                  size: "1.25rem",
                  class: "popup__content--icon"
                }),
                _createElementVNode("span", _hoisted_3, _toDisplayString($props.actionText), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["type"])
          ])
        ], true)
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue", "position"]))
}