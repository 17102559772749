<template>
  <div class="wa-template-btn">
    <form-title
      v-model="computedValue"
      :badge-text="badgeText"
      :is-toggle-disabled="isToggleDisabled"
      :is-text-disabled="isTitleTextDisabled"
      :heading-text="headingText"
      :should-show-badge="shouldShowBadge"
      :should-show-switch="shouldShowSwitch"
      :subtitle="subtitle"
      :tooltip-text="tooltipText"
    />
    <div v-if="computedValue" class="wa-template-btn__input-wrapper">
      <wa-template-buttons
        :buttons-data="buttonsData"
        :mode="mode"
        @add-new-button="$emit('add-new-button', $event)"
        @handle-delete-button="$emit('handle-delete-button', $event)"
        @handle-button-input-change="$emit('handle-button-input-change', $event)"
        @reorder-button-sections="$emit('reorder-button-sections', $event)"
      />
    </div>
    <t-button
      v-if="showActionButton"
      :disabled="disableMigrationButton"
      type="button"
      size="sm"
      @click="$emit('go-to-migration')"
    >
      {{ btnText }}
    </t-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import WaTemplateButtons from './WaTemplateButtons.vue';
import FormTitle from '../FormTitle/FormTitle.vue';

import type { TemplateButton } from '../types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'WaTemplateButtonSection',
  components: { WaTemplateButtons, FormTitle },
  props: {
    badgeText: {
      type: String,
      default: '',
    },
    buttonsData: {
      type: Object as PropType<TemplateButton>,
      default: () => ({}),
    },
    btnText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableMigrationButton: {
      type: Boolean,
      default: false,
    },
    isTitleTextDisabled: {
      type: Boolean,
      default: false,
    },
    isToggleDisabled: {
      type: Boolean,
      default: false,
    },
    headingText: {
      type: String,
      default: '',
    },
    showActionButton: {
      type: Boolean,
      default: false,
    },
    shouldShowBadge: {
      type: Boolean,
      default: false,
    },
    shouldShowSwitch: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: '',
    },
  },
  emits: [
    'handle-delete-button',
    'go-to-migration',
    'update:modelValue',
    'add-new-button',
    'handle-button-input-change',
    'reorder-button-sections',
  ],
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit('update:modelValue', value);
      },
    },
  },
});
</script>

<style lang="scss" src="./WaTemplateButtonSection.scss" />
