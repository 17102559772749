<template>
  <div v-if="showBalanceProgress && carrierCosts">
    <t-inline-banner v-if="isCurrentCycle && hasCarrierCostInvoice" class="mt-4">
      <template #icon>
        <info-linear />
      </template>
      {{ t('usage_overview.overview_section_balance_quota_update_pending') }}
    </t-inline-banner>
    <usage-progress-card
      :consumption="carrierCosts.consumption"
      :commitment="carrierCosts.commitment ?? 0"
      format="currency"
      :type="'balance'"
      :show-button="isCurrentCycle"
    >
      <template #icon>
        <t-thumbnail size="large">
          <wallet-minus-linear size="m" />
        </t-thumbnail>
      </template>
      <template #title>{{ t('usage_overview.overview_section_balance_spent_heading') }}</template>
      <template #button>
        <t-button :disabled="hasCarrierCostInvoice" size="sm" btn-style="secondary" @click="openCarrierCostsModal">
          {{ t('usage_overview.add_carrier_costs_top_up_balance') }}
        </t-button>
      </template>
    </usage-progress-card>

    <separator />
  </div>
</template>

<script lang="ts" setup>
import { WalletMinusLinear } from '@trengo/trengo-icons';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useQueryParameterOpenState } from '@/composables/useQueryParameterOpenState';
import { useBillingStore } from '@/store/pinia';

import Separator from './Separator.vue';
import UsageProgressCard from './UsageProgressCard.vue';
import { useUsageOverviewStore } from '../store';

const { isUpcoming, isUnknown, isCurrentCycle, selectedBreakdown } = storeToRefs(useUsageOverviewStore());
const { hasCarrierCostInvoice } = storeToRefs(useBillingStore());
const { handleOpen: openCarrierCostsModal } = useQueryParameterOpenState('addCarrierCostsModal');
const { t } = useI18n();

const carrierCosts = computed(() => selectedBreakdown.value.carrierCostBreakdown);
const showBalanceProgress = computed(() => !isUnknown.value && !isUpcoming.value);
</script>
