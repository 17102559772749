<template>
  <div ref="wrapper" class="relative" data-test="translation-option">
    <translation-button
      :disabled="!outboundTranslationStore.isActive"
      @click="outboundTranslationStore.toggleModal()"
    />
    <transition name="slide-fade-up">
      <translation-modal
        v-if="outboundTranslationStore.isModalOpen"
        @select-locale="(locale) => handleSelection(ticketId, locale, message)"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useKeyUpCallback } from '@/composables/useKeyUpCallback';
import { useInboundTranslationStore } from '@/store/pinia/translation/useInboundTranslationStore';

import TranslationButton from './TranslationButton.vue';
import TranslationModal from './TranslationModal.vue';
import { useOutboundTranslationStore } from './useOutboundTranslationStore';

import type { Locales } from './useLocales';

const props = defineProps<{ ticketId?: number; message: string }>();

const emit = defineEmits<{
  (e: 'new-translation', message: string): void;
}>();

const outboundTranslationStore = useOutboundTranslationStore();
const inboundTranslationStore = useInboundTranslationStore();

outboundTranslationStore.setChannelSupportStatus(props.ticketId);
inboundTranslationStore.setChannelSupportStatus(props.ticketId);

if (props.ticketId && outboundTranslationStore.ticketId !== props.ticketId) {
  outboundTranslationStore.ticketId = props.ticketId;
}

const handleSelection = async (ticket_id?: number, locale?: Locales, prompt?: string) => {
  await outboundTranslationStore.setTranslation({ ticket_id, locale, prompt });
  emit('new-translation', outboundTranslationStore.translation);
};

useKeyUpCallback('Escape', outboundTranslationStore.closeModal);
</script>
