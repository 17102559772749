<template>
  <div id="JumpToModal" ref="modal" class="modal" role="dialog" style="z-index: 99999" @mouseout.self="onMouseOut()">
    <div
      class="modal-centered modal-centered-lg"
      role="document"
      style="height: auto; min-height: auto; transform: translate(-50%, 0px); top: 10%"
    >
      <div class="p-8">
        <div
          class="flex w-full items-center overflow-hidden rounded-lg bg-grey-200 px-3 leading-none"
          style="height: 46px; line-height: 46px"
        >
          <i class="material-icons mr-3 rounded-lg text-grey-600" style="font-size: 23px">search</i>
          <input
            ref="jumpToSearch"
            v-model="search"
            type="text"
            :placeholder="$t('general.search_anything')"
            class="flex-1 bg-grey-200 leading-none text-grey-600"
            style="height: 40px; line-height: 40px"
            @keydown="handleKeyboardEvent($event)"
          />
        </div>
        <div class="-mx-8 mt-6" style="overflow-y: scroll; max-height: 50vh">
          <transition-group name="slide-fade">
            <div v-for="(jumpItem, i) in jumpItems" :key="'item_' + i">
              <div v-if="i === 0 || jumpItem.type !== jumpItems[i - 1].type" class="mb-1 ml-8 mt-2 text-grey-600">
                {{ jumpItem.type }}
              </div>

              <div
                class="pointer flex border-grey-200 px-10 py-2"
                :class="{ 'bg-grey-200': jumpItems.indexOf(jumpItem) === selectedIndex }"
                @click="executeJumpItemMethod(jumpItem)"
                @mouseover="selectedIndex = jumpItems.indexOf(jumpItem)"
              >
                <div v-if="jumpItem.faIcon" class="mr-4 flex justify-around" style="width: 24px">
                  <i :class="jumpItem.faIcon" class="fa md-20" />
                </div>
                <i v-else class="material-icons mr-4">{{ jumpItem.icon }}</i>
                {{ jumpItem.title }}
              </div>
            </div>
          </transition-group>
        </div>
        <div class="-mb-4 flex justify-between pb-3 pt-6">
          <div>
            <kbd>
              {{ $t('general.key_tab') }}
              <span class="material-icons material-icons md-12 font-bold">keyboard_tab</span>
            </kbd>
            or
            <kbd><i class="material-icons material-icons md-12 font-bold">north</i></kbd>
            <kbd><i class="material-icons material-icons md-12 font-bold">south</i></kbd>
            to navigate
          </div>
          <div>
            <kbd>
              {{ $t('general.key_return') }}
              <span class="material-icons material-icons md-12 font-bold">keyboard_return</span>
            </kbd>
            {{ $t('general.to_select') }}
          </div>
          <div>
            <kbd>{{ $t('general.key_escape') }}</kbd>
            {{ $t('general.to_cancel') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import _ from 'lodash';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { PERMISSION, SETTINGS_PERMISSIONS } from '@/Configs/Constants';
import { AUTH_URL } from '@/Configs/Constants/navigation/authUrl';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import eventBus from '@/eventBus';
import { useUserStore, useUsersStore } from '@/store/pinia';

export default defineComponent({
  props: {
    keysDown: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['open-edit-user-modal', 'close'],
  data() {
    return {
      search: null,
      selectedIndex: null,
      fuzeOptions: {
        // https://fusejs.io/api/options.html
        includeScore: true,
        threshold: 0.4,
        keys: [
          'type',
          'title',
          'id',
          'path',
          {
            name: 'altKeywords',
            weight: 2,
          },
        ],
      },
      trans: {
        navigate: this.$t('general.navigate'),
        filter_conversations: this.$t('general.filter_conversations'),
        configure: this.$t('general.configure'),
      },
    };
  },

  computed: {
    ...mapStores(useUserStore, useUsersStore),
    ...mapGetters({
      userThreads: 'chat/userThreads',
      groupThreads: 'chat/groupThreads',
    }),
    jumpItems() {
      // Inbox filters
      //  New / Assigned / Assigned to me / Closed/ Teams / Users / Channels / Labels
      let jumpItems = [
        { type: this.trans.navigate, icon: 'inbox', title: this.$t('navigation.tickets'), path: '/tickets' },
        {
          type: this.trans.navigate,
          icon: 'edit',
          title: this.$t('general.create_message'),
          altKeywords: ['New conversation', 'ticket', 'Create conversation'],
          execute: { method: 'createConversation' },
        },
        {
          type: this.trans.navigate,
          icon: 'notifications_none',
          title: this.$t('navigation.notifications'),
          execute: { method: 'showNotifications' },
        },
        {
          type: this.trans.navigate,
          icon: 'account_box',
          title: this.$t('navigation.contacts'),
          path: '/contacts/all',
        },
        {
          type: this.trans.navigate,
          icon: 'supervisor_account',
          title: this.$t('navigation.profiles'),
          path: '/profiles',
        },

        {
          type: this.trans.filter_conversations,
          icon: 'inbox',
          title: this.$t('tickets.filters_open'),
          execute: { method: 'filterTickets', args: ['inbox', 'open'] },
        },
        {
          type: this.trans.filter_conversations,
          icon: 'person',
          title: this.$t('tickets.filters_assigned_to_me'),
          execute: { method: 'filterTickets', args: ['me', 'assigned_me'] },
        },
        {
          type: this.trans.filter_conversations,
          icon: 'group',
          title: this.$t('tickets.filters_assigned'),
          execute: { method: 'filterTickets', args: ['inbox', 'assigned'] },
        },
        {
          type: this.trans.filter_conversations,
          icon: 'check',
          title: this.$t('tickets.filters_closed'),
          execute: { method: 'filterTickets', args: ['inbox', 'closed'] },
        },
        {
          type: this.trans.filter_conversations,
          icon: 'alternate_email',
          title: this.$t('tickets.filters_mentioned'),
          execute: { method: 'filterTickets', args: ['me', 'mentioned'] },
        },
        {
          type: this.trans.filter_conversations,
          icon: 'star',
          title: this.$t('tickets.filters_favorited'),
          execute: { method: 'filterTickets', args: ['me', 'favorited'] },
        },
        {
          type: this.trans.filter_conversations,
          icon: 'alarm',
          title: this.$t('tickets.filters_reminders'),
          execute: { method: 'filterTickets', args: ['me', 'reminders'] },
        },
        {
          type: this.trans.filter_conversations,
          icon: 'remove_red_eye',
          title: this.$t('tickets.filters_watching'),
          execute: { method: 'filterTickets', args: ['me', 'watching'] },
        },
        {
          type: this.trans.filter_conversations,
          icon: 'error_outline',
          title: this.$t('tickets.filters_spam'),
          execute: { method: 'filterTickets', args: ['inbox', 'spam'] },
        },

        {
          type: this.trans.navigate,
          icon: 'logout',
          title: this.$t('auth.logout'),
          execute: { method: 'logout' },
        },

        // Filters
        ...this.$root.teams.map((i) => ({
          type: this.trans.filter_conversations,
          icon: 'folder_open',
          title: this.$t('tickets.filters_teams') + ' ' + i.name,
          id: i.id,
          execute: {
            method: 'filterTickets',
            args: ['teams', i.id],
          },
        })),
        ...this.usersStore.users.map((i) => ({
          type: this.trans.filter_conversations,
          icon: 'folder_open',
          title: this.$t('tickets.filters_users') + ' ' + i.name,
          id: i.id,
          execute: {
            method: 'filterTickets',
            args: ['users', i.id],
          },
        })),
        ...this.$root.channels.map((i) => ({
          type: this.trans.filter_conversations,
          icon: 'folder_open',
          title: this.$t('tickets.filters_channels') + ' ' + i.text,
          id: i.id,
          execute: {
            method: 'filterTickets',
            args: ['channels', i.id],
          },
        })),
        ...this.$root.labels.map((i) => ({
          type: this.trans.filter_conversations,
          icon: 'folder_open',
          title: this.$t('tickets.filters_labels') + ' ' + i.name,
          id: i.id,
          execute: {
            method: 'filterTickets',
            args: ['labels', i.id],
          },
        })),
        ...this.$root.customViews.map((i) => ({
          type: this.trans.filter_conversations,
          icon: 'folder_open',
          title: this.$t('tickets.filters_custom_views') + ' ' + i.title,
          id: i.id,
          execute: {
            method: 'filterTickets',
            args: ['custom_views', i.id],
          },
        })),
      ];

      // Admin
      Array.prototype.push.apply(jumpItems, [
        ...(this.userStore.hasPermission(PERMISSION.REPORTING__GENERAL__MANAGE)
          ? [
              {
                type: this.trans.navigate,
                icon: 'assessment',
                title: this.$t('navigation.reports'),
                altKeywords: ['availability'],
                path: '/reports',
              },
            ]
          : []),

        ...(this.userStore.hasAnyPermission(SETTINGS_PERMISSIONS)
          ? [
              {
                type: this.trans.configure,
                icon: 'settings',
                title: this.$t('navigation.settings'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.PERMISSIONS__TEAMS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-users',
                title: this.$t('settings.teams'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__TEAMS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-user',
                title: this.$t('settings.users'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__USERS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__RULES__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-bolt',
                title: this.$t('settings.rules'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__RULES,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__CHATBOTS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-android',
                title: this.$t('settings.chatbots'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__CHATBOTS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__FLOWBOTS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-sitemap',
                title: this.$t('settings.flowbots'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__FLOWBOTS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__AUTO_REPLIES__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-reply',
                title: this.$t('settings.auto_replies'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__AUTO_REPLIES,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__WIDGET_AUTOMATIONS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-magic',
                title: this.$t('settings.widget_automations'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__WIDGET_AUTOMATION,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'far fa-file-invoice',
                title: this.$t('settings.subscription_settings'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'far fa-user-circle',
                title: this.$t('settings.account_settings'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__ACCOUNT_SETTINGS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'far fa-wallet',
                title: this.$t('settings.balance_settings'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__BALANCE_SETTINGS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__BUSINESS_HOURS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'far fa-alarm-clock',
                title: this.$t('settings.business_hours'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__BUSINESS_HOURS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__LOCATIONS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'far fa-map-marker-alt',
                title: this.$t('settings.locations'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__LOCATIONS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__WEBSITE_WIDGETS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-globe',
                title: this.$t('settings.website_widget'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__WEB_WIDGETS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__QUICK_REPLIES__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-comments-o',
                title: this.$t('settings.quick_replies'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__QUICK_REPLIES,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__LABELS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-tag',
                title: this.$t('settings.labels'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__LABELS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__VIEWS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-eye',
                title: this.$t('settings.views'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__CUSTOM_VIEWS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__CUSTOM_FIELDS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-list',
                title: this.$t('settings.custom_fields'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__CUSTOM_FIELDS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__CONTACT_GROUPS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-users',
                title: this.$t('settings.contact_groups'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__CONTACT_GROUPS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__TICKET_RESULTS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-trophy',
                title: this.$t('settings.ticket_results'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__TICKET_RESULTS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-bolt',
                title: this.$t('settings.apps_and_integrations'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__APPS_INTEGRATIONS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-bolt',
                title: this.$t('settings.rest_api'),
                altKeywords: ['settings', 'api token'],
                path: SETTINGS_URL.ADMIN__APPS_INTEGRATIONS__REST,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-bolt',
                title: this.$t('settings.webhooks'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__APPS_INTEGRATIONS__WEBHOOKS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-bolt',
                title: this.$t('settings.apps'),
                altKeywords: ['settings', 'sidebar', 'integrations'],
                path: SETTINGS_URL.ADMIN__APPS_INTEGRATIONS__PLUGINS,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE)
          ? [
              {
                type: this.trans.configure,
                faIcon: 'fa-bolt',
                title: this.$t('settings.zapier'),
                altKeywords: ['settings'],
                path: SETTINGS_URL.ADMIN__APPS_INTEGRATIONS__ZAPIER,
              },
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.PERMISSIONS__TEAMS__MANAGE)
          ? [
              ...this.$root.teams.map((i) => ({
                type: this.trans.configure,
                icon: 'settings',
                title: this.$t('settings.teams') + ' ' + i.name,
                id: i.id,
                path: `${SETTINGS_URL.ADMIN__TEAMS}/${i.id}`,
              })),
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)
          ? [
              ...this.usersStore.users.map((i) => ({
                type: this.trans.configure,
                icon: 'settings',
                title: this.$t('settings.users') + ' ' + i.name,
                id: i.id,
                modal: 'EditUserModal',
              })),
            ]
          : []),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE)
          ? [
              ...this.$root.channels.map((i) => ({
                type: this.trans.configure,
                icon: 'settings',
                title: this.$t('settings.channels') + ' ' + i.text,
                altKeywords: [i.type],
                id: i.id,
                path: `${SETTINGS_URL.ADMIN__CHANNELS2}/${i.type.toLowerCase()}/${i.id}`,
              })),
            ]
          : []), //.filter(i => { return !(['PLAYGROUND'].includes(i.type)); }),
        ...(this.userStore.hasPermission(PERMISSION.SETTINGS__LABELS__MANAGE)
          ? [
              ...this.$root.labels.map((i) => ({
                type: this.trans.configure,
                icon: 'settings',
                title: this.$t('settings.labels') + ' ' + i.name,
                id: i.id,
                path: `${SETTINGS_URL.ADMIN__LABELS}/${i.id}`,
              })),
            ]
          : []),
      ]);

      // Profile settings
      Array.prototype.push.apply(jumpItems, [
        {
          type: this.trans.configure,
          icon: 'person',
          title: this.$t('user_profile.title'),
          path: '/profile/overview',
        },
        {
          type: this.trans.configure,
          icon: 'person',
          title: this.$t('user_profile.password'),
          path: '/profile/password',
        },
        {
          type: this.trans.configure,
          icon: 'person',
          title: this.$t('user_profile.2fa'),
          path: '/profile/2fa',
        },
        {
          type: this.trans.configure,
          icon: 'person',
          title: this.$t('user_profile.preferences'),
          path: '/profile/preferences',
        },
      ]);

      if (this.$root.hasHelpcenter && this.userStore.hasPermission(PERMISSION.HELP_CENTER__GENERAL__MANAGE)) {
        // Help Center
        Array.prototype.push.apply(jumpItems, [
          {
            type: this.trans.configure,
            icon: 'help',
            title: this.$t('navigation.help_center'),
            path: '/help_center',
          },
        ]);
      }

      // Team Chat
      Array.prototype.push.apply(jumpItems, [
        // Colleagues (users)
        ...this.userThreads.map((t) => ({
          type: this.trans.navigate,
          icon: 'people',
          title: t.name + (t.getUnreadMessageCount() ? ' (' + t.getUnreadMessageCount() + ')' : ''),
          altKeywords: ['team', 'chat', t.name],
          path: '/chat/' + t.identifier,
        })),
        // Groups
        ...this.groupThreads.map((t) => ({
          type: this.trans.navigate,
          icon: 'people',
          title: t.name + (t.getUnreadMessageCount() ? ' (' + t.getUnreadMessageCount() + ')' : ''),
          altKeywords: ['team', 'chat', t.name],
          path: '/chat/' + t.identifier,
        })),
      ]);

      // Settings
      //  Teams / Users / Channels / Company Profile / Labels
      if (this.search) {
        const term = this.search.toLowerCase();
        // const fuse = new Fuse(jumpItems, this.fuzeOptions);
        // jumpItems = fuse.search(this.search).map(i =>  ({ ... i.item, score: i.score }));
        jumpItems = jumpItems.filter((i) => {
          return (
            i?.type?.toLowerCase().indexOf(term) > -1 ||
            i?.title?.toLowerCase().indexOf(term) > -1 ||
            i?.id == term ||
            i?.path?.toLowerCase().indexOf(term) > -1
          );
        });
      }

      // Group by type, take 6 items per type, return flattened
      return Object.values(_.groupBy(jumpItems, 'type'))
        .map((i) => i.slice(0, 6))
        .flat();
    },
  },
  watch: {
    search(val) {
      if (!val || val.length === 0) {
        this.selectedIndex = null;
      } else {
        this.selectedIndex = 0;
      }
    },
  },
  mounted() {
    eventBus.$on('jumpToModal', this.showModal);
  },
  beforeUnmount() {
    eventBus.$off('jumpToModal');
    this.hideModal();
  },

  methods: {
    showModal() {
      this.search = null;
      this.selectedIndex = null;

      // Fake click aways
      document.querySelector('.dropdown-overlay.nav-bg')?.click(); // Dropdown.vue click-away
      document.querySelector('.modal.in')?.click(); // Modal click-away

      $('#JumpToModal').modal('show');
      this.$refs.jumpToSearch.focus();
    },

    hideModal() {
      this.$emit('close');
      $('#JumpToModal').modal('hide');
    },

    executeJumpItemMethod(jumpItem) {
      this.hideModal();

      if (jumpItem.path) {
        this.$router.push(jumpItem.path);
        return;
      }

      if (jumpItem?.modal) {
        this.$emit('open-edit-user-modal', jumpItem.id);
        return;
      }

      const method = jumpItem?.execute?.method;
      switch (method) {
        case 'filterTickets':
        case 'createConversation':
          this.$router.push('/tickets');
          this.$nextTick(() => {
            if (method === 'filterTickets') {
              eventBus.$emit('tickets.filters.apply', ...jumpItem.execute.args);
            } else if (method === 'createConversation') {
              document.querySelector('#keyboardShortCutCreateTicket').click();
            }
          });
          return;
        case 'showNotifications':
          document.querySelector('#keyboardShortCutShowNotificationsRef').click();
          return;
        case 'logout':
          if (!confirm(this.$t('general.confirm_logout'))) {
            return;
          }
          window.location.href = AUTH_URL.LOGOUT;
          return;
      }
    },
    handleKeyboardEvent(event) {
      if (this.jumpItems.length < 1) {
        return;
      }
      if (event.key === 'ArrowDown' || (event.key === 'Tab' && !event.shiftKey)) {
        this.increaseSelectIndex(event);
      } else if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
        this.decreaseSelectIndex(event);
      } else if (event.key === 'Enter' && this.jumpItems[this.selectedIndex]) {
        this.executeJumpItemMethod(this.jumpItems[this.selectedIndex]);
      }

      if (event.key === 'Tab') {
        event.preventDefault();
      }
    },
    increaseSelectIndex() {
      if (this.selectedIndex === null || this.selectedIndex >= this.jumpItems.length - 1) {
        this.selectedIndex = 0;
      } else {
        this.selectedIndex++;
      }
    },
    decreaseSelectIndex() {
      if (this.selectedIndex <= 0) {
        this.selectedIndex = this.jumpItems.length - 1;
      } else {
        this.selectedIndex--;
      }
    },
    onMouseOut() {
      this.selectedIndex = 0;
      this.$emit('close');
    },
  },
});
</script>

<style scoped>
kbd {
  color: inherit;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.1em 0.5em;
  margin: 0 0.2em;
  box-shadow:
    0 1px 0px rgba(0, 0, 0, 0.2),
    0 0 0 2px #fff inset;
  background-color: #f7f7f7;
  text-transform: uppercase;
}
</style>
