import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex min-w-0 flex-1 select-none rounded-lg border-2 border-grey-200 p-4 text-black" }
const _hoisted_2 = { class: "flex w-full min-w-0" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = {
  class: "absolute",
  style: {"bottom":"-2px","right":"-2px"}
}
const _hoisted_5 = { class: "ml-4 mt-1 min-w-0 flex-1 select-none leading-none" }
const _hoisted_6 = { class: "mt-1 flex w-full" }
const _hoisted_7 = { class: "min-w-0 flex-1" }
const _hoisted_8 = { class: "text-ellipsis font-bold" }
const _hoisted_9 = { class: "ml-auto flex pl-4" }
const _hoisted_10 = {
  key: 0,
  class: "text-muted w-3/4 text-ellipsis text-ellipsis text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_user_modal = _resolveComponent("edit-user-modal")!
  const _component_avatar = _resolveComponent("avatar")!
  const _component_atomic_icon = _resolveComponent("atomic-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.openEditUserModal && _ctx.user && _ctx.userStore.hasPermission(_ctx.PERMISSION.PERMISSIONS__USERS__MANAGE))
      ? (_openBlock(), _createBlock(_component_edit_user_modal, {
          key: 0,
          "is-open": _ctx.openEditUserModal,
          "initial-user": _ctx.user,
          onUpdate: _ctx.handleSetUser,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditUserModal = false))
        }, null, 8 /* PROPS */, ["is-open", "initial-user", "onUpdate"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "cursor-pointer",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleOpenEditUserModal && _ctx.handleOpenEditUserModal(...args)))
        }, [
          _createVNode(_component_avatar, {
            width: "48",
            color: _ctx.user.color,
            abbr: _ctx.user.abbr,
            image: _ctx.user.profile_image
          }, null, 8 /* PROPS */, ["color", "abbr", "image"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "h-[18px] w-[18px] rounded-full border-2 border-white",
            style: _normalizeStyle({ background: _ctx.statusColor })
          }, null, 4 /* STYLE */)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.user.first_name) + " " + _toDisplayString(_ctx.user.last_name), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", null, [
              (_ctx.$root.agencyHasChatChannel)
                ? _withDirectives((_openBlock(), _createBlock(_component_atomic_icon, {
                    key: 0,
                    size: "16px",
                    icon: "fa-comment-alt",
                    class: _normalizeClass({ 'cursor-pointer': _ctx.chatTooltip }),
                    color: _ctx.chatStatusColor,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChatStatusChange()))
                  }, null, 8 /* PROPS */, ["class", "color"])), [
                    [_directive_tooltip, { content: _ctx.chatTooltip, placement: 'bottom', distance: 3 }]
                  ])
                : _createCommentVNode("v-if", true),
              (_ctx.$root.agencyHasVoipChannel)
                ? _withDirectives((_openBlock(), _createBlock(_component_atomic_icon, {
                    key: 1,
                    size: "16px",
                    class: _normalizeClass(["ml-2", { 'cursor-pointer': _ctx.voipTooltip }]),
                    icon: "fa-phone",
                    color: _ctx.voipStatusColor,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onVoipStatusChange()))
                  }, null, 8 /* PROPS */, ["class", "color"])), [
                    [_directive_tooltip, { content: _ctx.voipTooltip, placement: 'bottom', distance: 3 }]
                  ])
                : _createCommentVNode("v-if", true)
            ])
          ])
        ]),
        _createElementVNode("div", null, [
          (_ctx.user.teams.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.user.teams.map((t) => t.name).join(', ')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ])
  ]))
}