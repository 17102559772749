export const SUBSCRIPTION_FREQUENCY = {
  ANNUALLY: 'annually',
  MONTHLY: 'monthly',
} as const;

export const SUBSCRIPTION_ADDON_CHARGEBEE_TYPE = {
  USER_SEATS: 'userseats',
  OBSERVER_SEATS: 'observerseat',
  FLOWBOT: 'flowbot',
  CONVERSATION: 'conversations-100-standard',
  CARRIER_COST_BALANCE: 'eur25-credits',
  DATA_STORAGE: 'data-retention',
  VOICE_CHANNEL: 'voice',
  BROAD_CASTING: 'broadcasting',
  API_HIGH_RATE_LIMIT: 'apihighratelimit',
} as const;

export const SUBSCRIPTION_STATUS = {
  IN_TRIAL: 'in_trial',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  CANCEL_SCHEDULED: 'non_renewing',
} as const;

export const PERIOD_TYPE = {
  MONTH: 'month',
  YEAR: 'year',
  QUARTER: 'quarter',
  UNKNOWN: 'unknown',
  TRIAL: 'trial',
  UPCOMING: 'upcoming',
} as const;

export const ADDON_WITH_QUANTITY = {
  STANDARD_CONVERSATION_BLOCKS: 'standard_conversation_blocks',
  INTELLIGENT_CONVERSATION_BLOCKS: 'intelligent_conversation_blocks',
} as const;
