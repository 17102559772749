<template>
  <div class="mb-5 max-w-full rounded-xl border-1 border-grey-300 bg-white pt-6">
    <div class="mb-8 ml-6 flex items-center gap-3">
      <t-thumbnail>
        <component :is="icon"></component>
      </t-thumbnail>
      <div>
        <h5 class="t-text-h5 m-0 text-grey-800">
          {{ t(panelTitle) }}
        </h5>
      </div>
    </div>

    <div class="h-88 p-6 pt-0">
      <div class="relative h-72 xs:w-[400px] md:w-[600px] xl:w-[750px]">
        <canvas :id="chartName"></canvas>
        <div class="skeleton-background absolute left-0 top-0 z-10 h-full w-full">
          <div class="flex h-full w-full flex-col items-center justify-center text-center">
            <t-thumbnail>
              <component :is="icon"></component>
            </t-thumbnail>
            <p class="t-text-md-emphasize mb-2 mt-4 text-grey-800">{{ t(title) }}</p>
            <p class="t-text-sm mb-0 w-[70%] text-grey-700">
              {{ t(description) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Chart } from 'chart.js';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { emptyChartData } from '@/api/mocks/emptyChart';

import type { Component, PropType } from 'vue';

const props = defineProps({
  icon: {
    type: Object as PropType<Component>,
    required: true,
  },
  panelTitle: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  chartName: {
    type: String,
    default: 'emptyChart',
  },
});

const { t } = useI18n();
const emptyChart = ref(null);

const renderChart = () => {
  const ctx = document.getElementById(props.chartName)?.getContext('2d');
  if (emptyChart.value) {
    emptyChart.value.destroy();
  }

  emptyChart.value = new Chart(ctx, {
    type: 'bar',
    data: emptyChartData,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      barThickness: 16,
      borderRadius: 4,
    },
  });
};

onMounted(() => {
  renderChart();
});
</script>

<style scoped lang="scss">
.skeleton-background {
  background: linear-gradient(360deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}
</style>
