<template>
  <div class="row-body">
    <admin-section-title title="Whatsapp Templates"></admin-section-title>
    <div class="row-col">
      <div class="row-col">
        <div id="settings-primary" class="col-xs modal fade aside aside-md b-r w-[370px]">
          <div class="text-md white header flex justify-between h-12 px-4 items-center">
            <span class="text-base font-medium">
              {{ $t('whatsapp.whatsapp_templates') }}
            </span>
            <div class="flex flex-row items-center">
              <t-popover v-model="isFilterOpen" click-outside-close>
                <template #trigger>
                  <t-button btn-style="secondary" size="sm" class="!rounded-lg !px-2">
                    <div class="flex flex-row items-center">
                      <span>Label</span>
                      <caret-down-fill class="ml-1" size="1.5rem" />
                    </div>
                  </t-button>
                </template>

                <template #content>
                  <div class="absolute z-50 mt-2 w-80 rounded-xl border border-grey-300 bg-white shadow-500">
                    <t-input-search
                      key="search-labels"
                      v-model="search"
                      variant="primary-alt"
                      class="m-2"
                      @clear="search = ''"
                      @input="onSearch"
                    />
                    <t-divider class="mb-3" />
                    <ul class="w-full overflow-x-hidden overflow-y-scroll px-2">
                      <t-list-item-checkbox
                        v-for="label in getLabels"
                        :key="label.id"
                        class="py-2 px-3"
                        :label="label.name"
                        :model-value="selectedLabels.some((item) => item.id === label.id)"
                        @update:model-value="handleSelectItem(label)"
                      />
                    </ul>

                    <results-not-found v-if="!getLabels.length" :text="noResultsFound" />
                    <t-divider />
                    <div class="flex justify-end p-3">
                      <t-button size="sm" btn-style="secondary" class="mr-2" @click="clearFilters">
                        {{ $t('reports_v2.clear') }}
                      </t-button>
                      <t-button size="sm" @click="applyFilters">{{ $t('reports_v2.confirm') }}</t-button>
                    </div>
                  </div>
                </template>
              </t-popover>
              <div class="flex space-x-1.5 items-center">
                <a data-dismiss="modal" class="hidden-md-up text-muted m-l-auto m-r-sm m-l-1 text-lg">×</a>
                <a
                  class="m-l-auto btn-icon rounded text-grey-700 border border-grey-400 w-8 h-8 hover:bg-grey-200"
                  @click="$router.push('/admin/wa_templates/create')"
                >
                  <i class="material-icons md-18 !pl-[9px]">add</i>
                </a>
              </div>
            </div>
          </div>
          <div class="nav-settings light left b-primary row-col">
            <div class="row-row">
              <div class="row-body scrollable hove">
                <div class="row-inner">
                  <ul>
                    <li
                      v-for="item in filteredWaTemplates"
                      :key="item.id"
                      v-tooltip="{
                        content: item.title,
                        delay: { show: 300, hide: 50 },
                      }"
                      class="flex flex-grow items-center space-x-1.5 hover:bg-grey-200"
                    >
                      <circle-indicator
                        :indicator="item.status"
                        :color-classes="{
                          ACCEPTED: 'bg-success-500',
                          DECLINED: 'bg-error-500',
                          PENDING: 'bg-grey-500',
                        }"
                      />
                      <router-link
                        class="nav-settings-link text-truncate w-full"
                        :to="'/admin/wa_templates/' + item.id + ''"
                        :class="{ active: $route.params.id == item.id }"
                      >
                        <div class="flex items-center justify-between w-full">
                          <span class="flex-1 whitespace-nowrap overflow-hidden text-ellipsis">
                            {{ item.title }}
                          </span>
                          <span
                            v-if="item.labels.length > 0"
                            class="ml-2 whitespace-nowrap text-grey-700 bg-grey-300 py-1 px-2 text-xs font-semibold rounded"
                          >
                            {{ item.labels.at(0)?.name }}
                          </span>
                        </div>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-col">
          <router-view v-if="loaded" :key="$route.params.id"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CaretDownFill } from '@trengo/trengo-icons';

import { fetchWaTemplates } from '@/api/modules/waTemplates';
import CircleIndicator from '@/components/common/CircleIndicator/CircleIndicator.vue';
import ResultsNotFound from '@/components/Reporting/Components/V2/FilterDropdown/components/ResultsNotFound.vue';
import eventBus from '@/eventBus';

import type { WhatsAppLabel, WhatsAppTemplate } from '@/components/WaTemplates/types';

export default {
  name: 'WaTemplatesIndex',
  components: {
    ResultsNotFound,
    CircleIndicator,
    CaretDownFill,
  },
  data() {
    return {
      loaded: false,
      whatsAppTemplates: [] as WhatsAppTemplate[],
      filteredWaTemplates: [] as WhatsAppTemplate[],
      isFilterOpen: false,
      search: '',
      selectedLabels: [] as WhatsAppLabel[],
    };
  },
  computed: {
    uniqueLabels() {
      const labelSet = new Set();
      return this.whatsAppTemplates
        .filter((template: WhatsAppTemplate) => template.labels && template.labels.length > 0 && template.labels[0])
        .map((template: WhatsAppTemplate) => template.labels[0])
        .filter((label: WhatsAppLabel) => !labelSet.has(label.name) && labelSet.add(label.name));
    },
    getLabels() {
      if (!this.search) {
        return this.uniqueLabels;
      }

      return this.uniqueLabels.filter((label: WhatsAppLabel) =>
        label.name.toLowerCase().includes(this.search.toLowerCase()),
      );
    },
    noResultsFound() {
      const searchPhrasePadding = 30;
      const searchPhrase =
        this.search.length > searchPhrasePadding ? this.search.substring(0, searchPhrasePadding) + '...' : this.search;
      return this.$t('reports_v2.no_label_found', { label: searchPhrase });
    },
  },

  mounted() {
    this.loadList(false);
    eventBus.$on('trigger-loadlist', () => {
      this.loadList();
    });
  },

  methods: {
    async loadList() {
      try {
        const { data } = await fetchWaTemplates();
        this.whatsAppTemplates = data?.data || [];
        this.filteredWaTemplates = this.whatsAppTemplates;
        this.loaded = true;
      } catch (err) {
        console.error(err);
      }
    },
    applyFilters() {
      if (this.selectedLabels.length === 0) {
        this.filteredWaTemplates = this.whatsAppTemplates;
      } else {
        this.filteredWaTemplates = this.whatsAppTemplates.filter((template: WhatsAppTemplate) =>
          template.labels?.some((label: WhatsAppLabel) =>
            this.selectedLabels.some((item: WhatsAppLabel) => item.id === label.id),
          ),
        );
      }
      this.isFilterOpen = false;
      this.search = '';
    },
    clearFilters() {
      this.selectedLabels = [];
    },
    onSearch(value: string) {
      this.search = value;
    },
    handleSelectItem(label: WhatsAppLabel) {
      if (this.selectedLabels.some((item: WhatsAppLabel) => item.id === label.id)) {
        this.selectedLabels = this.selectedLabels.filter((item: WhatsAppLabel) => item.id !== label.id);
      } else {
        this.selectedLabels.push(label);
      }
    },
  },
};
</script>
