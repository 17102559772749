<template>
  <div>
    <t-inline-banner v-if="usageOverviewStore.isCurrentCycle && billingStore.hasConversationInvoice" class="mt-2">
      <template #icon>
        <info-linear />
      </template>
      {{ t('usage_overview.overview_section_conversation_quota_update_pending') }}
    </t-inline-banner>
    <usage-progress-card
      :consumption="conversationBreakdown.consumption ?? 0"
      :commitment="conversationBreakdown.commitment ?? 0"
      :in-progress-amount="conversationBreakdown.inProgress ?? 0"
      format="number"
      type="conversation"
      :show-button="usageOverviewStore.isCurrentCycle"
    >
      <template #icon>
        <t-thumbnail size="large">
          <comments-linear size="m" />
        </t-thumbnail>
      </template>
      <template #title>{{ t('usage_overview.overview_section_standard_conversations_heading') }}</template>
      <template #info>
        <info-fill v-tooltip="{ content: t('usage_overview.overview_section_conversations_tooltip') }" size="1rem" />
      </template>
      <template #button>
        <t-button
          size="sm"
          data-test="addConversationBlocksCTA"
          :disabled="billingStore.hasConversationInvoice"
          btn-style="secondary"
          @click="openConversationBlocksModal"
        >
          {{ t('usage_overview.overview_section_conversations_cta') }}
        </t-button>
      </template>
    </usage-progress-card>
    <separator />
  </div>
</template>

<script lang="ts" setup>
import { CommentsLinear, InfoFill } from '@trengo/trengo-icons';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useQueryParameterOpenState } from '@/composables/useQueryParameterOpenState';
import { useBillingStore } from '@/store/pinia';

import Separator from './Separator.vue';
import UsageProgressCard from './UsageProgressCard.vue';
import { useUsageOverviewStore } from '../store';

const { t } = useI18n();
const { handleOpen: openConversationBlocksModal } = useQueryParameterOpenState('addConversationBlocksModal');
const usageOverviewStore = useUsageOverviewStore();
const billingStore = useBillingStore();

const conversationBreakdown = computed(() => usageOverviewStore.selectedBreakdown?.conversationBreakdown);
</script>
