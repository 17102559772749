import { request } from '@/util/request';

import { ENDPOINT } from '../constants';
import { mockedUsageOverview } from '../mocks/usage';

import type { PeriodType } from '@/types';
import type { AxiosPromise } from 'axios';

export type Breakdown = {
  commitment: number;
  consumption: number;
  balance: number;
};
export type ConversationBreakdown = Breakdown & {
  in_progress: number;
};

export type BillingCycle = {
  chargebee_customer_id: string;
  chargebee_subscription_id: string;
  period_end: EpochTimeStamp;
  period_start: EpochTimeStamp;
  plan?: 'trial' | 'essentials' | 'boost' | 'pro' | 'unknown';
  billing_period_unit?: PeriodType;
  balance_breakdown: Breakdown;
  conversation_breakdown: ConversationBreakdown;
};

export type UsageResponse = {
  current_period: BillingCycle;
  billing_cycles: BillingCycle[];
  conversations_closing_in_next_cycle: number;
};

export const fetchUsageOverview = () => request<UsageResponse>(ENDPOINT.USAGE_BREAKDOWN, 'GET');

export const fetchMockedUsageOverview = (): AxiosPromise<UsageResponse> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: mockedUsageOverview,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
      });
    }, 1000);
  });
};
