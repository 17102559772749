import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative cursor-default" }
const _hoisted_2 = { class: "h-fit w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!
  const _component_phone_fill = _resolveComponent("phone-fill")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("a", _hoisted_1, [
    _createVNode(_component_avatar, {
      color: _ctx.user.color,
      abbr: _ctx.user.abbr,
      image: _ctx.user.profile_image
    }, null, 8 /* PROPS */, ["color", "abbr", "image"]),
    (_ctx.user.voip_status !== null)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "absolute left-6 top-1 h-fit rounded-full p-1 text-white",
          style: _normalizeStyle({ background: _ctx.voipColor })
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_phone_fill, { size: "0.5rem" })
          ])
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true)
  ])), [
    [_directive_tooltip, { popperClass: 'chat_sidebar', placement: 'left', content: `${_ctx.user.first_name} ${_ctx.user.last_name}` }]
  ])
}