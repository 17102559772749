<template>
  <div>
    <scheduled-change-banner v-if="subscription.isScheduledToDowngrade">
      <template #heading>{{ t('subscription.subscription_manager__downgrade_scheduled_title') }}</template>
      <template #subheading>
        {{ t('subscription.subscription_manager__downgrade_scheduled_subtitle', { date: subscriptionDueDate }) }}
      </template>
    </scheduled-change-banner>
    <scheduled-change-banner v-if="isCancelScheduled" with-button>
      <template #heading>{{ t('subscription.subscription_manager__cancel_scheduled_title') }}</template>
      <template #subheading>{{ t('subscription.subscription_manager__cancel_scheduled_subtitle') }}</template>
      <template #buttonLabel>{{ t('subscription.subscription_manager__contact_support') }}</template>
    </scheduled-change-banner>
    <div class="mb-6 rounded-xl border-1 border-grey-300 bg-white">
      <div class="p-6">
        <div class="flex items-center gap-4 pb-6">
          <t-thumbnail class="bg-white">
            <newspaper-linear size="1rem" />
          </t-thumbnail>
          <div class="flex-grow">
            <div class="t-text-h5 font-bold text-grey-800">
              {{ t('subscription.subscription_manager__your_plan_title', { planName: subscription.subscriptionName }) }}

              <span class="t-text-xs font-semibold text-grey-600">
                ({{ capitalize(subscription.subscriptionFrequency) }})
              </span>
            </div>
            <div class="t-text-sm text-grey-700">
              {{ t('subscription.subscription_manager__thanks_for_using_trengo') }}
            </div>
          </div>
        </div>
        <div class="xs:t-text-sm xl:t-text-md grid grid-cols-2 gap-4">
          <div
            v-for="(item, index) in includedFeatures"
            :key="index"
            class="flex items-center gap-3 rounded-xl bg-grey-200 p-3 text-grey-800"
          >
            <t-thumbnail class="bg-white" size="small">
              <component :is="item.icon"></component>
            </t-thumbnail>
            <div class="flex-grow">{{ item.title }}</div>
            <t-badge variant="default" text="incl." />
          </div>
        </div>
      </div>
      <div class="border-t-1 border-grey-300" />
      <div class="p-6">
        <h5 class="t-text-h5 pb-1 text-grey-800">{{ t('subscription.addon_additions_title') }}</h5>
        <div class="xs:t-text-sm xl:t-text-md grid grid-cols-2 gap-4">
          <div
            v-for="addon in subscriptionAddons"
            :key="addon.item_chargebee_type"
            class="flex items-center gap-3 rounded-xl bg-grey-200 p-3 text-grey-800"
          >
            <t-thumbnail class="bg-white" size="small">
              <component :is="addon.icon"></component>
            </t-thumbnail>
            {{ addon.title }}
          </div>
        </div>
      </div>
      <div class="border-t-1 border-grey-300" />

      <div class="flex flex-col gap-4 p-6">
        <div class="t-text-h5 font-semibold text-grey-800">
          {{ t('subscription.subscription_manager__billing_title') }}
          <div v-if="!isCancelScheduled" class="t-text-sm font-normal text-grey-600">
            {{
              t('subscription.subscription_manager__billing_subtitle_your_next_payment_of', {
                billingFrequency: subscription.subscriptionFrequency,
              })
            }}
            <span class="text-grey-800">
              {{
                t('subscription.subscription_manager__billing_subtitle_price_is_due', { price: subscriptionDuePrice })
              }}
              <span v-if="subscriptionDueDate">
                {{ t('subscription.subscription_manager__billing_subtitle_on_date', { date: subscriptionDueDate }) }}
              </span>
            </span>
          </div>
        </div>
        <div class="flex gap-4 text-grey-800">
          <button
            class="t-text-sm flex w-[400px] items-center gap-2 rounded-xl border-1 border-grey-300 bg-grey-100 p-3 hover:bg-grey-200 active:bg-grey-300"
            @click="editPaymentMethods"
          >
            <t-thumbnail class="bg-white" size="tiny">
              <credit-card-linear size="1rem" />
            </t-thumbnail>

            <div>{{ t('subscription.subscription_manager__payment_details') }}</div>
            <div class="ml-auto">
              <external-link-linear size="20px" />
            </div>
          </button>
          <button
            class="t-text-sm flex w-[400px] items-center gap-2 rounded-xl border-1 border-grey-300 bg-grey-100 p-3 hover:bg-grey-200 active:bg-grey-300"
            @click="editBillingAddress"
          >
            <t-thumbnail class="bg-white" size="tiny">
              <pin-linear />
            </t-thumbnail>
            <span>{{ t('subscription.subscription_manager__billing_details') }}</span>
            <div class="ml-auto">
              <external-link-linear size="20px" />
            </div>
          </button>
        </div>
      </div>
      <div class="border-t-1 border-grey-300" />
      <div class="flex justify-between gap-2 p-6">
        <t-button v-if="!isCancelScheduled" id="bb-cancel" size="md" btn-style="danger-alt" @click="cancelSubscription">
          {{ t('subscription.subscription_manager__cancel_subscription') }}
        </t-button>
        <div class="flex justify-end gap-3">
          <t-button size="md" btn-style="secondary" @click="showBillingHistory">
            <span>{{ t('subscription.subscription_manager__billing_history') }}</span>
          </t-button>
          <t-button
            v-if="!isCancelScheduled"
            size="md"
            btn-style="primary"
            class="flex items-center"
            @click="editSubscription"
          >
            {{ t('subscription.subscription_manager__edit_subscription') }}
            <chevron-right-linear size="1.125rem" class="ml-1 inline" />
          </t-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  NewspaperLinear,
  CreditCardLinear,
  PinLinear,
  ExternalLinkLinear,
  ChevronRightLinear,
} from '@trengo/trengo-icons';
import { capitalize } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import { openChargebeePortal } from '@/api/modules/subscriptionChargebee';
import { useChargebeeDuePayment } from '@/composables/useChargebeeDuePayment';
import { SUBSCRIPTION_ADDON_CHARGEBEE_TYPE, SUBSCRIPTION_STATUS } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { useSubscriptionStore, useUserStore } from '@/store/pinia';
import { formatAmount } from '@/util/amount';
import { segmentTrackEvent } from '@/util/analytics/segmentAnalytics';
import { formatPrice } from '@/util/currency';

import ScheduledChangeBanner from './ScheduledChangeBanner.vue';
import { MULTIPLIER_EURO_25, SEGMENT_TRACKING_DETAILS, SUBSCRIPTION_ADDONS } from '../constants';

import type { SubscriptionAddonDetail, SubscriptionAddonUsageBased } from '@/types';

const { t } = useI18n();
const { subscription } = storeToRefs(useSubscriptionStore());
const userStore = useUserStore();
const { subscriptionDuePrice, subscriptionDueDate } = useChargebeeDuePayment();

const brightAppId = window.BRIGHT_APP_ID;

const cbInstance = window.Chargebee.init({
  site: window.CHARGEBEE_SITE,
  publishableKey: window.CHARGEBEE_PUBLISHABLE_KEY,
});
const cbPortal = cbInstance.createChargebeePortal();

const includedFeatures = computed(() =>
  subscription.value.pricingModel === 'usage' ? subscription.value.includedItems : [],
);

const getAddonTitle = (subscriptionDetail: SubscriptionAddonDetail, item: SubscriptionAddonUsageBased) => {
  switch (true) {
    case subscriptionDetail?.item_chargebee_type === SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.CARRIER_COST_BALANCE:
      return t(subscriptionDetail.title, { carrierCosts: formatPrice(item.quantity * MULTIPLIER_EURO_25) });

    case subscriptionDetail?.is_quantity_based: {
      return t(subscriptionDetail.title, { quantity: formatAmount(item.quantity) });
    }
    default:
      return t(subscriptionDetail?.title);
  }
};

const subscriptionAddons = computed(() => {
  try {
    const subscriptionAddons = subscription.value.addons as SubscriptionAddonUsageBased[];

    if (subscriptionAddons.length === 0) {
      return [];
    }

    const addonMap = new Map(subscriptionAddons.map((item) => [item.type, item]));

    return SUBSCRIPTION_ADDONS.filter((addon) => addonMap.has(addon.item_chargebee_type)).map((addon) => ({
      ...addon,
      title: getAddonTitle(addon, addonMap.get(addon.item_chargebee_type)),
    }));
  } catch {
    return [];
  }
});

const isCancelScheduled = computed(
  () => subscription.value.subscriptionStatus === SUBSCRIPTION_STATUS.CANCEL_SCHEDULED,
);

const initPortal = () => {
  cbInstance.setPortalSession(() => {
    return openChargebeePortal().then((response) => response.data);
  });
};

const editPaymentMethods = () => {
  cbPortal.openSection({
    sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES,
  });
};

const handleSegmentTracking = (event: keyof typeof SEGMENT_TRACKING_DETAILS) => {
  segmentTrackEvent({
    subscription_id: subscription.value.subscriptionId,
    ...SEGMENT_TRACKING_DETAILS[event],
  });
};

const showBillingHistory = () => {
  cbPortal.openSection({
    sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY,
  });

  handleSegmentTracking('BILLING_HISTORY');
};

const editBillingAddress = () => {
  cbPortal.openSection({
    sectionType: window.Chargebee.getPortalSections().ADDRESS,
  });
};

const cancelSubscription = () => {
  if (!window.Brightback) {
    return false;
  }
  // FIXME: Refactor this with routes store
  const currentPage = window.location.origin + window.location.pathname;

  window.Brightback.handleDataPromise({
    app_id: brightAppId,
    email: userStore.user?.email,
    subscription_id: subscription.value.subscriptionId,
    save_return_url: currentPage,
    cancel_confirmation_url: currentPage + '?cancelled',
  }).then((result: any) => {
    if (result.valid) {
      handleSegmentTracking('CANCEL_BEGIN');
      window.location = result.url;
    }
  });
};

const editSubscription = () => {
  handleSegmentTracking('EDIT');
  router.push(`${SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS}/${subscription.value.subscriptionId}`);
};

onMounted(() => {
  initPortal();

  // FIXME: Refactor this with routes store https://linear.app/trengo/issue/WALL-615/add-full-path-to-routes-store-and-update-subscriptionmanagers
  if (window.location.search.includes('cancelled')) {
    handleSegmentTracking('CANCELLED');
  }
});
</script>
